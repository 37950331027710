import React from 'react'
import styled from 'styled-components'

import {
	Checkbox as MCheckbox,
	FormControlLabel,
	FormControlLabelProps,
} from '@mui/material'

import { FieldProps } from '../../model/types'
import ThickIcon from '../icons/ThickIcon'
import theme from '../theme'
import { pxToRem } from '../../libs/style'

const StyledFormControlLabel = styled(FormControlLabel)<{
	$fontWeight?: number
}>`
	.MuiFormControlLabel-label {
		color: ${theme.palette.primary.main};
		font-weight: ${props => props.$fontWeight};
		font-size: ${pxToRem(14)}rem;
	}

	.MuiFormControlLabel-label::first-letter {
		text-transform: uppercase;
	}
`

const StyledCheckbox = styled(MCheckbox)<{
	$readonly?: boolean
}>`
	background-color: ${theme.palette.common.white};
	border: 2px solid ${theme.palette.grey[600]};
	border-radius: 5px;
	height: 20px;
	width: 20px;
	min-width: 20px;
	padding: 0;
	margin-right: ${theme.spacing(1)};

	&:hover {
		border-color: ${theme.palette.violet[800]};
	}

	&.Mui-disabled {
		opacity: 0.5;
		border-color: ${props =>
			!!props.$readonly && theme.palette.greyCustom.main};
		background-color: ${props =>
			!!props.$readonly && theme.palette.common.white};
	}

	&.Mui-focusVisible {
		border-color: ${theme.palette.grey[600]};
		box-shadow: 0 0 0 1px ${theme.palette.common.white},
			0 0 0 3px ${theme.palette.secondary.light};
		background-color: ${theme.palette.common.white};
	}

	&.Mui-checked {
		background-color: ${theme.palette.primary.main};
		border-color: ${theme.palette.primary.main};
		color: ${theme.palette.background.default};

		&:hover {
			background-color: ${theme.palette.violet.dark};
			border-color: ${theme.palette.violet.dark};
		}

		&.Mui-disabled {
			opacity: 0.5;
			border-color: ${theme.palette.greyCustom.main};
			background-color: ${theme.palette.greyCustom.main};
		}

		&.Mui-focusVisible {
			background-color: ${theme.palette.violet.dark};
			border-color: ${theme.palette.violet.dark};
			box-shadow: 0 0 0 1px ${theme.palette.common.white},
				0 0 0 3px ${theme.palette.secondary.light};
		}
	}

	.MuiSvgIcon-root {
		display: none;
	}
`

interface Props extends Omit<FormControlLabelProps, 'form'>, FieldProps {
	readonly?: boolean
	uncheck?: boolean
	overrideChecked?: boolean
	fontWeight?: number
}

const Checkbox: React.FC<Props> = ({
	control,
	form,
	field,
	label,
	readonly = false,
	uncheck = false,
	fontWeight = 500,
	overrideChecked,
	...props
}) => {
	const checked =
		overrideChecked !== undefined
			? overrideChecked
			: (field.checked ||
					(field.value as unknown as boolean) === true ||
					props.checked === true ||
					props.value === true) &&
			  !uncheck

	return (
		<StyledFormControlLabel
			$fontWeight={fontWeight}
			className={'telo-checkbox'}
			control={
				<StyledCheckbox
					checked={checked}
					onChange={props.onChange || field.onChange}
					onBlur={field.onBlur}
					name={field.name || props.name}
					$readonly={readonly}
					checkedIcon={<ThickIcon />}
				/>
			}
			label={label}
			{...props}
		/>
	)
}

export default Checkbox
