import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Document, Page, pdfjs } from 'react-pdf'
import SignatureCanvas from 'react-signature-canvas'

import { Field, Formik } from 'formik'
import { selectAppLang, selectStoreId } from '../features/app/selectors'
import dialogActions from '../features/dialog/actions'
import { selectDialog } from '../features/dialog/selectors'
import signDocumentsActions from '../features/signDocuments/actions'
import { selectConsentGuarantorByPatientId } from '../features/signDocuments/selectors'
import storesActions from '../features/stores/actions'
import {
	selectPrivacyPolicyDocumentBlob,
	selectPrivacyPolicyDocuments,
} from '../features/stores/selectors'
import { getFirstValidDate, formatDate, getLocalizedDate } from '../libs/time'
import {
	DOCUMENT_SIGNED_BY,
	PrivacyPolicyDocumentGuarantor,
} from '../model/appointment'
import { PrivacyPolicyDialogType } from '../model/dialog'
import { useTeloDispatch, useTeloSelector } from '../store'
import Button from '../styleguide/buttons/Button'
import IconButton from '../styleguide/buttons/IconButton'
import Dialog from '../styleguide/dialog/Dialog'
import DialogTitle from '../styleguide/dialog/DialogTitle'
import CloseIcon from '../styleguide/icons/CloseIcon'
import ThickIcon from '../styleguide/icons/ThickIcon'
import {
	GuardianBox,
	GuardianCheckboxes,
	GuardianLabel,
	SignatureDialogActions,
	SignatureDialogContent,
	SignatureDialogContentText,
	SignatureDialogPDFDocumentWrapper,
	SignBlockFooter,
	SignBlockNote,
	SignBlockTitle,
	SignBlockWrapper,
} from '../styleguide/SignatureDialog.style'
import Yup from '../yup'
import { useAddSignedPrivacyPolicyDocumentsMutation } from '../services/signedPrivacyPolicyDocumentsApi'
import { DOCUMENT_STATUS } from '../model/signedPrivacyPolicyDocuments'
import { getPrivacyDocumentExpirationDate } from './AdminDocuments/helpers'
import Checkbox from '../styleguide/forms/Checkbox'
import Grid from '../styleguide/Grid'
import Input from '../styleguide/forms/Input'
import { selectCurrentlyDisplayedExam } from '../features/exams/selectors'

const CANVAS_WRAPPER = 'signature-canvas-wrapper'

const DEFAULT_GUARANTOR: PrivacyPolicyDocumentGuarantor = {
	signedBy: DOCUMENT_SIGNED_BY.PATIENT,
	signerFullName: '',
	kinship: '',
}

const isPrivacyPolicyDialog = (
	dialog: any,
): dialog is PrivacyPolicyDialogType =>
	typeof dialog === 'object' && dialog.documentID

const PrivacyPolicyDocumentDialog = ({ isPatientSignature = false }) => {
	const { t } = useTranslation()
	const dispatch = useTeloDispatch()

	const dialog = useTeloSelector(selectDialog)
	const storeId = useTeloSelector(selectStoreId)
	const selectedPrivacyPolicyDocuments = useTeloSelector(
		selectPrivacyPolicyDocuments,
	)

	const exam = useTeloSelector(selectCurrentlyDisplayedExam)
	const examDefaultExpiringDocsDate =
		exam &&
		getFirstValidDate([
			exam.externalAppointment.date,
			exam.updatedAt,
			exam.createdAt,
		])

	const isOpen = isPrivacyPolicyDialog(dialog)
	// const isOpen = isPrivacyPolicyDialog(dialog) || isPatientSignature

	const lang = useTeloSelector(selectAppLang)

	const [disabledButton, setDisabledButton] = useState(true)
	const [clearButtonVisible, setClearButtonVisible] = useState(false)
	const [numPages, setNumPages] = useState(0)

	const signPad = useRef<SignatureCanvas>(null)

	const privacyPolicyDocumentGuarantor = useTeloSelector(
		selectConsentGuarantorByPatientId(
			isPrivacyPolicyDialog(dialog) ? dialog.patientId : '',
		),
	)

	const blobDocUrl = useTeloSelector(selectPrivacyPolicyDocumentBlob)

	const onDocumentLoadSuccess = (numPages: number) => setNumPages(numPages)

	pdfjs.GlobalWorkerOptions.workerSrc = pdfjs.GlobalWorkerOptions.workerSrc =
		pdfjsWorker

	const selectedDocument = selectedPrivacyPolicyDocuments.find(
		({ _id }) => isPrivacyPolicyDialog(dialog) && dialog.documentID === _id,
	)

	const fileRef = selectedDocument?.ref
	const documentName = selectedDocument?.name
	useEffect(() => {
		fileRef &&
			documentName &&
			dispatch(storesActions.getPrivacyPolicyPdf(documentName, fileRef))
	}, [dispatch, fileRef, documentName])

	const isPdf =
		selectedDocument &&
		!!selectedDocument.path &&
		selectedDocument.path.length > 0

	const signatureDate = getLocalizedDate(new Date(), lang)

	const [createSignedDocuments] = useAddSignedPrivacyPolicyDocumentsMutation()

	useEffect(() => {
		const canvasElements = document.querySelectorAll(
			`.${CANVAS_WRAPPER} canvas`,
		) as NodeListOf<Element>

		canvasElements.forEach(inputElement => {
			inputElement.setAttribute(
				'data-testid',
				'privacy-policy-signature-canvas',
			)
		})
	}, [])

	return isPrivacyPolicyDialog(dialog) ? (
		<Dialog open={isOpen || isPatientSignature}>
			<DialogTitle>
				{t('privacyPolicy.title')}
				<IconButton
					aria-label="close"
					onClick={() => {
						dispatch(dialogActions.closeDialog())
						dispatch(storesActions.clearPrivacyPolicyFileUrl())
					}}
					sx={{
						position: 'absolute',
						right: 16,
						top: 8,
						color: theme => theme.palette.common.white,
					}}
				>
					<CloseIcon />
				</IconButton>
			</DialogTitle>
			<Formik
				initialValues={{
					...DEFAULT_GUARANTOR,
					...privacyPolicyDocumentGuarantor,
				}}
				enableReinitialize={true}
				validateOnChange={true}
				validateOnBlur={true}
				validateOnMount={true}
				validationSchema={Yup.object().shape({
					signedBy: Yup.string().required(),
					signerFullName: Yup.string().when('signedBy', {
						is: 'PATIENT',
						then: () => Yup.string().trim(),
						otherwise: () => Yup.string().trim().required(),
					}),
					kinship: Yup.string().when('signedBy', {
						is: 'PARENT',
						then: () => Yup.string().trim().required(),
					}),
				})}
				onSubmit={values => {
					createSignedDocuments({
						status: DOCUMENT_STATUS.SIGNED,
						patient: dialog.patient!,
						signedBy: values.signedBy || DOCUMENT_SIGNED_BY.PATIENT,
						signerFullName: values.signerFullName?.trim() || '',
						kinship: values.kinship?.trim() || '',
						storeDocumentId: selectedDocument?._id || '',
						signedDate: new Date().toISOString(),
						ref: fileRef || '',
						name: selectedDocument?.name,
						expirationDate: getPrivacyDocumentExpirationDate(
							examDefaultExpiringDocsDate,
							selectedDocument?.duration,
							selectedDocument?.durationUnit,
						),
						duration: selectedDocument?.duration,
						durationUnit: selectedDocument?.durationUnit,
						storeId: storeId,
						signatureImg:
							(signPad.current &&
								signPad.current?.getTrimmedCanvas().toDataURL('image/png')) ||
							'',
					})
						.unwrap()
						.then(payload => {
							selectedDocument &&
								dispatch(
									signDocumentsActions.setSignDocumentByExam({
										examId: dialog.examId,
										document: {
											name: selectedDocument.name,
											storeId: storeId,
											documentId: selectedDocument._id,
											patientId: dialog.patientId,
											signatureImg:
												(signPad.current &&
													signPad.current
														?.getTrimmedCanvas()
														.toDataURL('image/png')) ||
												'',
											date: new Date().getTime().toString(),
											mandatory: selectedDocument.active || false,
											duration: selectedDocument.duration,
											durationUnit: selectedDocument.durationUnit,
											expirationDate: getPrivacyDocumentExpirationDate(
												examDefaultExpiringDocsDate,
												selectedDocument.duration,
												selectedDocument.durationUnit,
											),
											...values,
										},
									}),
								)
						})
						.catch(error => console.error('rejected', error))
					selectedDocument &&
						dispatch(
							signDocumentsActions.setSignDocumentByExam({
								examId: dialog.examId,
								document: {
									name: selectedDocument.name,
									storeId: storeId,
									documentId: selectedDocument._id,
									patientId: dialog.patientId,
									signatureImg:
										(signPad.current &&
											signPad.current
												?.getTrimmedCanvas()
												.toDataURL('image/png')) ||
										'',
									date: new Date().getTime().toString(),
									mandatory: selectedDocument.active || false,
									duration: selectedDocument.duration,
									durationUnit: selectedDocument.durationUnit,
									expirationDate: getPrivacyDocumentExpirationDate(
										examDefaultExpiringDocsDate,
										selectedDocument.duration,
										selectedDocument.durationUnit,
									),
									...values,
								},
							}),
						)
					dispatch(
						signDocumentsActions.setPatientGuarantor({
							patientId: dialog.patientId,
							pg: values,
						}),
					)
					dispatch(storesActions.clearPrivacyPolicyFileUrl())
					dispatch(dialogActions.closeDialog())
				}}
			>
				{({ values, setValues, submitForm, isValid }) => (
					<>
						<SignatureDialogContent>
							{isPdf && blobDocUrl && (
								<SignatureDialogPDFDocumentWrapper>
									<Document
										file={blobDocUrl}
										onLoadSuccess={({ numPages }) =>
											onDocumentLoadSuccess(numPages)
										}
									>
										{Array.from({ length: numPages }, (_, index) => {
											const pageNumber = index + 1
											return (
												<Page
													key={`page_${pageNumber}`}
													pageNumber={pageNumber}
												/>
											)
										})}
									</Document>
								</SignatureDialogPDFDocumentWrapper>
							)}
							{!isPdf && (
								<SignatureDialogContentText>
									{selectedDocument?.text}
								</SignatureDialogContentText>
							)}
							<SignBlockTitle>{t('privacyPolicy.agree')}</SignBlockTitle>
							<SignBlockNote>
								{t('privacyPolicy.parentOrLegalDisclaimer')}
							</SignBlockNote>
							<GuardianBox>
								<GuardianCheckboxes>
									<GuardianLabel>
										{t('privacyPolicy.parentOrLegalLabel')}
									</GuardianLabel>
									<Field
										component={Checkbox}
										name="isParent"
										label={t('privacyPolicy.parent')}
										checked={values.signedBy === DOCUMENT_SIGNED_BY.PARENT}
										onChange={(_: React.ChangeEvent, checked: boolean) => {
											setValues({
												...DEFAULT_GUARANTOR,
												signedBy: checked
													? DOCUMENT_SIGNED_BY.PARENT
													: DOCUMENT_SIGNED_BY.PATIENT,
											})
										}}
									/>
									<Field
										component={Checkbox}
										name="isLegalGuardian"
										label={t('privacyPolicy.legalGuardian')}
										checked={values.signedBy === DOCUMENT_SIGNED_BY.GUARDIAN}
										onChange={(_: React.ChangeEvent, checked: boolean) => {
											setValues({
												...DEFAULT_GUARANTOR,
												signedBy: checked
													? DOCUMENT_SIGNED_BY.GUARDIAN
													: DOCUMENT_SIGNED_BY.PATIENT,
											})
										}}
									/>
								</GuardianCheckboxes>
								{values.signedBy !== DOCUMENT_SIGNED_BY.PATIENT && (
									<Grid container spacing={4}>
										<Grid item sm={6}>
											<Field
												component={Input}
												name="signerFullName"
												label={t('privacyPolicy.parentOrLegalName')}
												fullWidth
												mandatory
											/>
										</Grid>
										{values.signedBy === DOCUMENT_SIGNED_BY.PARENT && (
											<Grid item sm={6}>
												<Field
													component={Input}
													name="kinship"
													label={t('privacyPolicy.parentOrLegalKinship')}
													fullWidth
													mandatory
												/>
											</Grid>
										)}
									</Grid>
								)}
							</GuardianBox>
							<SignBlockWrapper className={CANVAS_WRAPPER}>
								<SignatureCanvas
									penColor="black"
									onBegin={() => setClearButtonVisible(true)}
									onEnd={() => setDisabledButton(false)}
									ref={signPad}
								/>
								<SignBlockFooter>
									<time>{formatDate(signatureDate, 'dd/MM/yyyy', lang)}</time>
									<Button
										onClick={() => {
											signPad.current && signPad.current.clear()
											setDisabledButton(true)
											setClearButtonVisible(false)
										}}
										endIcon={<CloseIcon />}
										variant="text"
										underlined={true}
										hidden={!clearButtonVisible}
									>
										{t('app.clear')}
									</Button>
								</SignBlockFooter>
							</SignBlockWrapper>
							<SignBlockNote>
								{t('privacyPolicy.signaturePlaceholder')}
							</SignBlockNote>
						</SignatureDialogContent>
						<SignatureDialogActions>
							<Button
								data-testid="privacy-policy-signature-agree"
								className="ott-checkInModal-SignButton"
								onClick={submitForm}
								endIcon={<ThickIcon />}
								disabled={disabledButton || !isValid}
							>
								{t('privacyPolicy.agreeAndSign')}
							</Button>
						</SignatureDialogActions>
					</>
				)}
			</Formik>
		</Dialog>
	) : null
}

export default PrivacyPolicyDocumentDialog
