import { createApi } from '@reduxjs/toolkit/query/react'
import qs from 'qs'
import { staggeredBaseQueryWithBailOut } from '../libs/services'
import { unversionedApiUrl } from './common'
import { ServiceBaseUrl } from './serviceApi'
import { ServicesApiTag, ServicesReducerPath } from './constants'
import { ReasonsUpdatePartial, Reason } from '../model/reasons'
import { useDebounce } from '../hooks/useDebounce'
import { getCommonHeaders } from '../libs/fetchJson'
import { id } from 'date-fns/locale'
type ResponseData = { data: Reason[]; totalEntries: number }
export const reasonsApi = createApi({
	reducerPath: ServicesReducerPath.ReasonsApi,
	tagTypes: [ServicesApiTag.Reasons],
	baseQuery: staggeredBaseQueryWithBailOut(unversionedApiUrl),
	endpoints: builder => ({
		get: builder.query<
			ResponseData,
			{
				limit?: number
				page?: number
				q?: string
				type?: string
				admin?: boolean | string
			}
		>({
			query: query => ({
				url: `${ServiceBaseUrl.Reasons}?${qs.stringify(query)}`,
				headers: getCommonHeaders({ addContentType: true }),
			}),
			providesTags: [ServicesApiTag.Reasons],
		}),

		createReasons: builder.mutation<void, ReasonsUpdatePartial>({
			query: body => ({
				url: `${ServiceBaseUrl.Reasons}`,
				method: 'POST',
				body: JSON.stringify(body),
				headers: getCommonHeaders({ addContentType: true }),
			}),
			invalidatesTags: [ServicesApiTag.Reasons],
		}),

		updateReasons: builder.mutation<void, ReasonsUpdatePartial>({
			query: body => {
				const { _id, ...updateData } = body

				return {
					url: `${ServiceBaseUrl.Reasons}/${_id}`,
					method: 'PUT',
					body: JSON.stringify(updateData),
					headers: getCommonHeaders({ addContentType: true }),
				}
			},
			invalidatesTags: [ServicesApiTag.Reasons],
		}),

		enableReasons: builder.mutation<
			void,
			{ id: string; updateData: Omit<Reason, '_id'>; value: boolean }
		>({
			query: ({ id, value, updateData }) => ({
				url: `${ServiceBaseUrl.Reasons}/${id}`,
				method: 'PATCH',
				body: JSON.stringify({
					...updateData,
					enabled: value,
				}),
			}),
			invalidatesTags: [ServicesApiTag.Reasons],
		}),
	}),
})

const useGetDebounced = (query: {
	q: string
	limit?: number
	page?: number
	type: string
	admin?: boolean | string
}) => {
	const debouncedQuery = useDebounce(query.type ? query.type : query.q, 500)
	return reasonsApi.useGetQuery(
		{ ...query, type: debouncedQuery },
		{
			refetchOnMountOrArgChange: true,
		},
	)
}

export const useReasonsApi = {
	getDebounced: useGetDebounced,
}

export const {
	useGetQuery,
	useCreateReasonsMutation,
	useUpdateReasonsMutation,
	useEnableReasonsMutation,
} = reasonsApi
