import React from 'react'

import Box, { BoxProps } from '@mui/material/Box'

import { Field, Form } from 'formik'
import { useTranslation } from 'react-i18next'
import styled, { css, keyframes } from 'styled-components'

import VisualAcuityAutocomplete from '../components/Autocomplete/visualAcuity.autocomplete'
import BackIconButton from '../components/BackIconButton'
import { pxToRem } from '../libs/style'
import Tooltip from '../styleguide/Tooltip'

import Button from './buttons/Button'
import CircularProgress from './CircularProgress'
import { defaultInputStyles } from './defaultStyles'
import Grid from './Grid'
import AddIcon from './icons/AddIcon'
import ArrowRightIcon from './icons/ArrowRightIcon'
import EditIcon from './icons/EditIcon'
import ErrorIcon from './icons/ErrorIcon'
import VideoIcon from './icons/VideoIcon'
import LinkNoStyle from './LinkNoSyle'
import SearchBox from './SearchBox'
import theme from './theme'
import PageTitle from './typography/PageTitle'
import Subtitle from './typography/Subtitle'

export const fontSize = {
	instrumentTable: '0.875rem',
	small: '0.8rem',
	drawerSmall: '0.7rem',
}

export const PageWrapper = styled(Grid)`
	height: 100%;
	padding: 0 0 ${theme.spacing(2)};
	background-color: #ffffff;
`

export const PageWrapperFlex = styled(PageWrapper)`
	display: flex;
	flex-direction: column;
`

export const PageWrapperFlexWithAutoHeight = styled(PageWrapperFlex)`
	height: auto;
`

export const pageWrapperPadding = css`
	padding-left: ${theme.spacing(4)};
	padding-right: ${theme.spacing(4)};
	margin-left: ${theme.spacing(2)};
	margin-right: ${theme.spacing(2)};

	${theme.breakpoints.down('lg')} {
		padding-left: ${theme.spacing(2)};
		padding-right: ${theme.spacing(2)};
		margin-left: ${theme.spacing(2)};
		margin-right: ${theme.spacing(2)};
	}
`
export const pageWrapperMargin = css`
	margin-left: ${theme.spacing(4)};
	margin-right: ${theme.spacing(4)};

	${theme.breakpoints.down('lg')} {
		margin-left: ${theme.spacing(2)};
		margin-right: ${theme.spacing(2)};
	}
`

export const scrollableWrapperPadding = css`
	padding-left: ${theme.spacing(4)};
	padding-right: ${theme.spacing(4)};

	${theme.breakpoints.down('lg')} {
		padding-left: ${theme.spacing(2)};
		padding-right: ${theme.spacing(2)};
	}
`

export const disableEditingInput = `
	.MuiRadio-root.Mui-disabled .MuiIconButton-label {
		color: ${theme.palette.grey[500]};
		background-color: ${theme.palette.grey[200]};
		border-radius: 50%;
	}

	.MuiRadio-root.Mui-disabled .MuiIconButton-label .MuiSvgIcon-root:first-child {
		color: transparent;
	}

	.MuiTypography-root.Mui-disabled {
		color: ${theme.palette.grey[500]};
	}

	.MuiTypography-root.Mui-disabled {
		color: ${theme.palette.grey[500]};
	}
`

export const Main = styled.main`
	${pageWrapperPadding}
	background-color: ${theme.palette.common.white};
`

export const MainFlexFullWidth = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	overflow-y: hidden;
`

export const MainFlexFullWidthNoOverflow = styled(MainFlexFullWidth)`
	overflow-y: auto;
`

export const StyledForm = styled(Form)<{
	$enableoverflowhidden?: boolean
	$enableoverflowauto?: boolean
	$justifyContent?:
		| 'flex-start'
		| 'flex-end'
		| 'center'
		| 'space-between'
		| 'space-around'
}>`
	flex: 1;
	display: flex;
	flex-direction: column;
	${props =>
		props.$enableoverflowhidden === true ? 'overflow-y: hidden;' : ''}
	${props => (props.$enableoverflowauto === true ? 'overflow-y: auto;' : '')}
  ${props =>
		props.$justifyContent ? `justify-content: ${props.$justifyContent};` : ''}
`

export const StyledFieldsWrapper = styled.div<{
	$enableoverflowhidden?: boolean
	$enableoverflowauto?: boolean
}>`
	flex: 1;
	display: flex;
	flex-direction: column;
	${props =>
		props.$enableoverflowhidden === true ? 'overflow-y: hidden;' : ''}
	${props => (props.$enableoverflowauto === true ? 'overflow-y: auto;' : '')}
`

export const ScrollableContainer = styled.div<{
	disableOverflowAuto?: boolean
	disableOverflowVis?: boolean
	withPadding?: boolean
	$pt?: number
	$pb?: number
	fullWidth?: boolean
}>`
	max-width: 100%;
	${({ fullWidth }) => (fullWidth ? 'width: 100%;' : '')}
	flex: 1;
	${({ disableOverflowVis }) =>
		disableOverflowVis === true ? '' : 'overflow: visible;'}
	${({ disableOverflowAuto }) =>
		disableOverflowAuto === true ? '' : 'overflow-y: auto;'}
  background-color: ${theme.palette.background.default};

	${({ $pt }) => $pt && `padding-top: ${theme.spacing($pt)};`}
	${({ $pb }) => $pb && `padding-bottom: ${theme.spacing($pb)};`}
  ${({ withPadding }) => withPadding && scrollableWrapperPadding}
`

export const ButtonContainerFullWidth = styled.div<{
	oneColumnStepper?: boolean
	isFixed?: boolean
	noBorder?: boolean
}>`
	${props =>
		props.isFixed
			? `
		position: fixed;
		bottom: 0;
		left: 0;
		background-color: ${theme.palette.background.default};
	`
			: ''}
	${props =>
		props.noBorder ? '' : `border-top: solid 1px ${theme.palette.grey[400]};`}
	width: 100%;
	padding: ${theme.spacing(2)} ${theme.spacing(4)};
	display: grid;
	${props =>
		props.oneColumnStepper === true
			? 'grid-template-columns: 100%;'
			: 'grid-template-columns: 33% 33% 33%;'}
`

export const HeaderExamPageWrapper = styled.div`
	display: flex;
	justify-content: end;
	padding-bottom: ${theme.spacing(0.75)};
	gap: ${theme.spacing(1.5)};
`

export const ButtonsFooterContainer = styled(ButtonContainerFullWidth)`
	display: flex;
	justify-content: flex-end;
	padding: ${theme.spacing(2)} ${theme.spacing(4)};
	position: sticky;
	bottom: 0;
	background-color: white;
	z-index: ${theme.zIndex.mobileStepper};
	gap: ${theme.spacing(2)};
`

export const TitleWrapper = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
	justify-content: space-between;
	padding-top: ${theme.spacing(2)};
`

export const SmallerPageTitle = styled(PageTitle)`
	font-size: 1.2rem;
	white-space: nowrap;
`

export const FieldsWrapper = styled.div<{
	$disableEditing?: boolean
	$extrapaddinginput?: boolean
	$noPadding?: boolean
}>`
	${pageWrapperPadding};
	flex: 1;
	clear: both;

	.Mui-disabled.Mui-disabled .MuiOutlinedInput-notchedOutline,
	.MuiOutlinedInput-notchedOutline.Mui-disabled,
	.MuiInputBase-root.Mui-disabled .MuiOutlinedInput-notchedOutline {
		border: 1px solid ${theme.palette.grey[900]};
		background-color: ${theme.palette.grey[100]};
		border-radius: ${pxToRem(12)}rem;
	}

	.MuiAutocomplete-root,
	.MuiInputBase-root {
		.MuiOutlinedInput-root .MuiAutocomplete-input,
		.MuiSelect-select.MuiSelect-outlined,
		.MuiOutlinedInput-root .MuiAutocomplete-endAdornment,
		.MuiInputBase-input.MuiOutlinedInput-input {
			z-index: 1;
		}
	}

	${({ $extrapaddinginput }) =>
		$extrapaddinginput && `padding-top: ${theme.spacing(5.4)};`}
	${({ $noPadding }) => $noPadding && `padding: 0;`}
  ${theme.breakpoints.down('lg')} {
		${({ $noPadding }) => $noPadding && `padding: 0;`}
	}

	${props =>
		!!props.$disableEditing &&
		`
		${disableEditingInput}
  `}
`

export const NoteField = styled(Field)<{ $fullheightinput?: boolean }>`
	${({ $fullheightinput }) =>
		$fullheightinput &&
		`
    
	height: 100%;

	div {
		height: 100%;
	}

	input {
		height: 100%;
	}
 
 `}
`

export const FieldsWrapperWithTopPadding = styled(FieldsWrapper)`
	padding: 0 ${theme.spacing(2)};
	padding-top: ${theme.spacing(2)};
`

export const FieldsWrapperNoPadding = styled.div`
	flex: 1;
`

export const FieldsWrapperGrid = styled(Grid)`
	${pageWrapperPadding}
	flex: 1;
`

export const FieldsWrapperClear = styled(FieldsWrapper)`
	clear: both;
`

export const BiggerSubtitle = styled(Subtitle)`
	font-size: 1.6rem;
	font-weight: normal;
	margin-bottom: ${theme.spacing(3)};
	${pageWrapperPadding}
	display: flex;
	justify-content: space-between;
	text-transform: capitalize;
`

export const BiggerSubtitleFLeft = styled(BiggerSubtitle)`
	flex: 1;
	float: left;
`

export const PrevButtonFooter = styled(Button)`
	grid-column: 1;
	place-self: center left;
	text-decoration: underline;

	svg {
		width: ${pxToRem(20)}rem;
		height: ${pxToRem(20)}rem;
	}

	.MuiButton-startIcon {
		margin-right: 0;

		+ .textButton--label:not(:empty) {
			margin-left: ${theme.spacing(1)};
		}
	}

	.MuiButton-label span {
		text-transform: uppercase;
		font-weight: bold;
	}
`

export const NextButtonFooter = styled(Button)`
	grid-column: 3;
	place-self: center right;
`

export const FooterPrevLink = styled(LinkNoStyle)`
	grid-column: 1;
	place-self: center left;
`

export const NextButtonFooterLeftArrowIcon = styled(ArrowRightIcon)`
	width: 1rem;
	height: auto;
	margin-left: ${theme.spacing(1)};
`

export const TitleAndDate = styled.div`
	${pageWrapperMargin}
	display: flex;
	justify-content: space-between;
	border-bottom: solid 1px ${theme.palette.grey[400]};
	padding: ${theme.spacing(2)};
`

export const DateWrapper = styled.div`
	display: flex;
	align-items: center;
`

export const AppointmentDate = styled.div`
	text-transform: uppercase;
	margin-left: ${theme.spacing(1)};
	line-height: 1;
`

const EditButtonStyle = styled(Button)`
	color: ${theme.palette.secondary.main};
	font-size: 1rem;
`

export const UnderlinedButtonLabel = styled.span`
	padding-bottom: 0.1em;
	border-bottom: solid 1px currentColor;
`

export const EditButton: React.FC<{
	disabled?: boolean
	onClick?: () => void
}> = props => {
	const { t } = useTranslation()
	return (
		<EditButtonStyle
			data-testid="edit-button"
			startIcon={<EditIcon />}
			variant="text"
			{...props}
		>
			<UnderlinedButtonLabel>{t('app.edit')}</UnderlinedButtonLabel>
		</EditButtonStyle>
	)
}

export const InstrumentInput = styled(Field)`
	.MuiInputBase-root.Mui-disabled {
		color: ${theme.palette.grey[700]};
	}

	.MuiInputBase-root.Mui-disabled:not(:focus) {
		color: ${theme.palette.grey[500]};
	}

	.MuiInputBase-input {
		text-align: ${({ alignText }) => alignText || 'center'};
		font-size: ${fontSize.instrumentTable};
		line-height: 1.3571;

		${theme.breakpoints.down('lg')} {
			font-size: ${fontSize.small};
		}
	}

	${defaultInputStyles}
`

export const pretestRowGap = 1

export const intrumentsTableCss = css`
	display: grid;
	grid-column-gap: ${theme.spacing(4)};

	${theme.breakpoints.down('lg')} {
		grid-column-gap: ${theme.spacing(1)};
	}

	justify-items: center;
	align-items: center;
	font-size: ${fontSize.instrumentTable};

	${theme.breakpoints.down('lg')} {
		font-size: ${fontSize.small};
	}

	margin-bottom: ${theme.spacing(pretestRowGap)};

	> *:first-child {
		place-self: center left;
	}

	.MuiFormControlLabel-label {
		font-size: ${fontSize.instrumentTable};

		${theme.breakpoints.down('lg')} {
			font-size: ${fontSize.small};
		}
	}
`

export const PretestField = styled(Field)`
	margin-right: ${theme.spacing(1)};

	input {
		text-align: center;
		line-height: 1;
	}

	${defaultInputStyles}
`

export const StyledSearchBox = styled(SearchBox)`
	width: 32rem;
`

export const ProgressContainer = styled.div<{ withPadding?: boolean }>`
	width: 100%;
	flex: 1;
	display: flex;
	justify-content: center;
	align-items: center;
	${props => (props.withPadding ? `padding: ${theme.spacing(4)} 0;` : '')}
`

export const TitleWithLeftBorder = css`
	padding: ${pxToRem(8)}rem ${pxToRem(12)}rem;
	border-left: 4px solid ${theme.palette.aquamarine[500]};
`

export const subtitleWithBackgroundStyle = css`
	background-color: ${theme.palette.grey[100]};
	color: ${theme.palette.grey[700]};
	padding: 0.25rem ${theme.spacing(2)} 0;
	margin-bottom: ${theme.spacing(3)};
	text-transform: uppercase;
	clear: both;
`
export const SubtitleWithBackground = styled.div<{
	maxWidth?: string
	noMargin?: boolean
}>`
	max-width: ${({ maxWidth }) => maxWidth || 'none'};
	${subtitleWithBackgroundStyle}
	${props => props.noMargin && `margin-bottom: 0;`}
`

export const SubtitleWithBackgroundAndMargin = styled(SubtitleWithBackground)`
	${pageWrapperMargin}
`

export const SubtitleWithBackgroundAndNoMargin = styled.div`
	${subtitleWithBackgroundStyle}
	margin: 0;
`

export const EditDiscardButtonWrapper = styled.div`
	position: sticky;
	top: -${pxToRem(1)}rem;
`

export const EditDiscardButtonWrapperRight = styled(EditDiscardButtonWrapper)`
	display: flex;
	flex: 1;
	justify-content: flex-end;
	float: right;
	${pageWrapperMargin}
	background-color: ${theme.palette.common.white};
	z-index: 1;
	margin-top: -1px;
	margin-bottom: ${theme.spacing(3)};
`

export const SectionTitle = styled(Subtitle).attrs({ className: 'title' })`
	margin-bottom: ${theme.spacing(2)};
	color: ${theme.palette.primary.main};
`

export const AdminTabContentWrapper = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	width: 100%;
`

export const VideoIconSmall = styled(VideoIcon)`
	width: ${pxToRem(20)}rem;
`

export const visuallyHidden = css`
	border: 0;
	clip: rect(0 0 0 0);
	margin: 0;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
	height: 1px;
	white-space: nowrap;
`

export const HiddenLegend = styled.legend`
	${visuallyHidden}
`

export const ClipboardButtons = styled.div<{ justifyContent?: string }>`
	display: grid;
	justify-content: ${props =>
		props.justifyContent ? props.justifyContent : 'flex-end'};
	grid-auto-flow: column;
	gap: ${theme.spacing(2)};
`

const ShareWrapper = styled.div<{ $autowidth?: boolean }>`
	width: ${({ $autowidth }) => ($autowidth ? 'auto' : '100%')};
	display: flex;
	justify-content: flex-end;
	align-items: center;
	padding-bottom: ${theme.spacing(2)};
`

const ShareButton = styled(Button)`
	color: ${theme.palette.secondary.main};

	&:not([disabled]) {
		border-color: ${theme.palette.secondary.main} !important;
	}

	.button--label {
		text-transform: none;
	}
`

export const ShareCircle = styled.div<{
	isActive: boolean
	noMargin?: boolean
}>`
	background-color: ${props =>
		props.isActive ? theme.palette.success.main : theme.palette.grey[300]};
	border-radius: 50%;
	overflow: hidden;
	${props => (props.noMargin ? '' : `margin-left: ${theme.spacing(2)};`)}
	width: ${pxToRem(10)}rem;
	height: ${pxToRem(10)}rem;
`

export const ShareRow: React.FC<{
	isActive: boolean
	onClick: () => void
	disabled: boolean
	isLoading?: boolean
	className?: string
	$autowidth?: boolean
}> = ({ isActive, onClick, disabled, isLoading, className, $autowidth }) => {
	const { t } = useTranslation()

	return (
		<ShareWrapper className={className} $autowidth={$autowidth}>
			<Tooltip
				title={t(
					!isActive ? 'app.startSharingSection' : 'app.stopSharingSection',
				)}
			>
				<ShareButton
					variant="outlined"
					disabled={disabled}
					onClick={onClick}
					startIcon={isActive && <ErrorIcon />}
					endIcon={isLoading && <CircularProgress size="1em" />}
				>
					{t(!isActive ? 'app.startSharing' : 'app.stopSharing')}
				</ShareButton>
			</Tooltip>
			<ShareCircle isActive={isActive} />
		</ShareWrapper>
	)
}

type ActionProps = {
	highlighted?: boolean
}

export const MainActions = styled.div`
	width: 100%;

	div:nth-child(2) {
		margin-left: ${theme.spacing(3)};
	}
`

export const Action = styled.div`
	display: inline-block;
	color: ${theme.palette.secondary.main};
	opacity: ${({ highlighted }: ActionProps) => (highlighted ? 1 : 0.5)};
	cursor: pointer;
`

export const Wrapper = styled.div`
	padding: ${theme.spacing(3)} ${theme.spacing(2)};
	height: 100%;
	display: flex;
	flex-direction: column;
	background-color: ${theme.palette.background.default};
`

export const Label = styled.div`
	color: ${theme.palette.primary.main};
	padding: ${theme.spacing(1.5)} 0;
`

export const EmptyMessage = styled.div`
	flex: 1;
	display: flex;
	align-items: center;
	justify-content: center;
	text-transform: uppercase;
`

export const UploadImagePlaceholder = styled.div`
	background-color: ${theme.palette.background.default};
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	min-height: ${pxToRem(114)}rem;
	min-width: 198px;
	overflow: hidden;
	border-radius: ${theme.shape.borderRadius}px;
	border: solid 1px ${theme.palette.grey[500]};
	margin-right: ${pxToRem(12)}rem;
	margin-bottom: ${pxToRem(12)}rem;
`

export const UploadImageBox = styled.img`
	display: block;
	max-height: ${pxToRem(112)}rem;
	max-width: 100%;
`

export const GridCell = styled(Grid)<{
	$alignright?: boolean
}>`
	${({ $alignright }) =>
		$alignright === true ? `text-align: right; justify-content: flex-end;` : ''}
`

export const IconButtonStyled = styled(Button)`
	min-width: auto;

	.MuiButton-startIcon {
		margin: 0;
	}
`

export const AutocompleteStyled = styled.div`
	${defaultInputStyles}
	.MuiInputLabel-outlined:not(.Mui-disabled, .Mui-error) {
		color: ${theme.palette.greyCustom.main};
	}

	.MuiInputBase-root.Mui-disabled {
		background-color: rgb(245, 246, 248);
	}

	.MuiAutocomplete-hasPopupIcon .MuiInputBase-root {
		padding-right: ${pxToRem(theme.spacing(5))}rem;
	}

	.MuiInputBase-root {
		padding: 0;
		border-radius: ${pxToRem(theme.spacing(1.5))}rem;
		min-height: ${theme.spacing(6)};

		& .MuiAutocomplete-input {
			padding: ${theme.spacing(1.5, 0, 1.5, 2)};
		}
	}

	.MuiTextField-root .MuiInputLabel-root {
		transform: translate(${theme.spacing(1.75)}, ${theme.spacing(1.5)}) scale(1);

		&[data-shrink='true'],
		&.Mui-focused {
			transform: translate(${theme.spacing(1.75)}, -${theme.spacing(1.125)})
				scale(0.75);
		}
	}
`
export const Spacer = styled.div<{ top?: boolean }>`
	${({ top }) => (top === true ? `margin-top: ${theme.spacing(4)};` : '')}
`

export const VisualAcuityAutocompleteWithSpace = styled(
	VisualAcuityAutocomplete,
)`
	&.MuiAutocomplete-hasPopupIcon
		.MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'],
	&.MuiAutocomplete-hasClearIcon
		.MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] {
		padding-right: 65px;
	}
`

export const HELPER_WRAPPER_Z_INDEX = 9999

export const HelpersWrapper = styled.div`
	position: fixed;
	bottom: 0;
	left: 0;
	z-index: ${HELPER_WRAPPER_Z_INDEX};
	display: flex;
	align-items: flex-end;

	> div {
		margin-right: ${theme.spacing(2)};
	}
`

export const ExpandableMenuOverlay = styled.span`
	display: block;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.3);
	z-index: 2;
`

export const ExpandableMenuWrapper = styled.div<{ open?: boolean }>`
	z-index: 1;
	transform: translateY(calc(100% - 36px));
	transition: transform 500ms ease;
	${({ open }) =>
		open &&
		css`
			z-index: 5;
			transform: translateY(0);
		`}
`

export const ExpandableMenuContent = styled.div<{ width: number }>`
	max-height: 0;
	width: 0;
	background-color: ${theme.palette.background.default};
	transition: width 500ms ease 500ms, max-height 0.15s ease-out;

	&.open {
		max-height: 750px;
		width: ${({ width }) => pxToRem(width)}rem;
		transition: max-height 0.25s ease-in;
		box-shadow: ${pxToRem(5)}rem 0 ${pxToRem(15)}rem ${pxToRem(5)}rem
			rgba(0, 0, 0, 0.25);
		transform: translateY(0);
	}
`

export const ExpandableMenuHolder = styled.div`
	padding: ${pxToRem(30)}rem ${pxToRem(20)}rem;
`

export const opacity = keyframes`
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
`

export const FavouriteButton = styled.button<{
	$mLeft?: boolean
}>`
	display: flex;
	align-items: center;
	${({ $mLeft }) =>
		!$mLeft
			? `margin-right: ${theme.spacing(2)};`
			: `margin-left: ${theme.spacing(1)};`}
	cursor: pointer;

	svg {
		${({ $mLeft }) =>
			!$mLeft
				? `margin-right: ${theme.spacing(1)};`
				: `margin-left: ${theme.spacing(1)};`}
	}
	&[disabled] {
		cursor: auto;
		svg {
			opacity: 0.5;
		}
	}
`

export const FavouriteLabel = styled.span`
	color: ${theme.palette.primary.main};
	margin-top: ${pxToRem(4)}rem;

	&::first-letter {
		text-transform: uppercase;
	}
`

export const Option = styled.div`
	display: grid;
	grid-template-columns: auto 1rem;
	grid-template-rows: 1fr;
	align-items: center;
	width: 100%;
	color: ${theme.palette.grey['500']};

	svg {
		fill: ${theme.palette.grey[500]};

		&.full {
			fill: ${theme.palette.grey[400]};
		}
	}
`
export const FlexOption = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 100%;
	color: ${theme.palette.grey['500']};

	svg {
		fill: ${theme.palette.grey[500]};

		&.full {
			fill: ${theme.palette.grey[400]};
		}
	}
`

export const OptionLabel = styled.span`
	color: ${theme.palette.primary.main};
`

export const PlusIconWithMargin = styled(AddIcon)`
	margin-right: ${theme.spacing(0.75)};
	vertical-align: middle;
`

export const OverflowEllipsis = styled.div`
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 100%;
`

export const OverflowEllipsisCapitalized = styled(OverflowEllipsis)`
	text-transform: capitalize;
`

export const CommonExamPageContentCard = styled.div<{
	$noMargin?: boolean
	$noPadding?: boolean
	$noBorder?: boolean
	$fullHeight?: boolean
}>`
	display: flex;
	padding: ${({ $noPadding }) =>
		$noPadding === true ? '0' : `${theme.spacing(3)} ${theme.spacing(2)}`};
	flex-direction: column;
	align-items: flex-start;
	gap: ${theme.spacing(4)};
	align-self: stretch;
	margin: ${({ $noMargin }) => ($noMargin === true ? '' : theme.spacing(3))};
	border-radius: ${theme.spacing(3)};
	background: ${theme.palette.common.white};
	box-shadow: ${({ $noPadding }) =>
		!$noPadding && '0px 2px 2px -1px rgba(10, 22, 70, 0.1)'};

	#visualAcuities-content {
		padding: 0;
	}

	${({ $fullHeight }) => $fullHeight && `height: 100%`}
`

export const CommonExamPageSectionDivider = styled(Box)<
	BoxProps & { noMargin?: boolean }
>`
	width: 100%;
	height: 1px;
	border-bottom: 1px solid ${theme.palette.grey[400]};
	margin: ${({ noMargin }) => (!noMargin ? theme.spacing(2) : '0')} 0;
`

export const CommonExamPageInstrumentHeader = styled.div.attrs(
	(props: { $cols?: string; $lm?: boolean }) => ({
		$cols: props.$cols || '1fr 2fr 2fr',
		$lm: props.$lm || false,
	}),
)`
	background-color: ${theme.palette.grey[200]};
	border-bottom: 1px solid ${theme.palette.grey[500]};
	display: grid;
	grid-column-gap: ${theme.spacing(4)};

	${theme.breakpoints.down('lg')} {
		grid-column-gap: ${theme.spacing(1)};
	}

	font-size: ${fontSize.instrumentTable};

	${theme.breakpoints.down('lg')} {
		font-size: ${fontSize.small};
	}

	margin-bottom: ${theme.spacing(1)};
	padding: ${theme.spacing(1.5)} 0;

	.MuiFormControlLabel-label {
		font-size: ${fontSize.instrumentTable};

		${theme.breakpoints.down('lg')} {
			font-size: ${fontSize.small};
		}
	}

	border-top-right-radius: ${theme.spacing(1)};
	border-top-left-radius: ${theme.spacing(1)};
	grid-template-columns: ${props => props.$cols};
	clear: both;

	.lp {
		padding-left: ${theme.spacing(0.5)};
	}

	${props =>
		props.$lm === true
			? `
		& > *:first-child {
	 		margin-left: ${theme.spacing(1)};
 		}
	`
			: ''}
`

export const CommonExamPageInstrumentRow = styled.div.attrs(
	(props: { $cols?: string; $lm?: boolean }) => ({
		$cols: props.$cols || '1fr 2fr 2fr',
		$lm: props.$lm || false,
	}),
)`
	display: grid;
	grid-column-gap: ${theme.spacing(4)};

	${theme.breakpoints.down('lg')} {
		grid-column-gap: ${theme.spacing(1)};
	}

	font-size: ${fontSize.instrumentTable};
	grid-template-columns: ${props => props.$cols};
	clear: both;
	align-items: center;
	margin-bottom: ${theme.spacing(1)};

	${props =>
		props.$lm === true
			? `
		& > *:first-child {
	 		margin-left: ${theme.spacing(1)};
 		}
	`
			: ''}
`

export const ButtonBack = styled(BackIconButton)`
	margin-bottom: ${theme.spacing(4)};
	color: ${theme.palette.black.main};
`

export const ButtonContainerFullWidthFlex = styled(ButtonContainerFullWidth)`
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: ${theme.palette.background.default};
`

export const ButtonContainerFullWidthFlexEnd = styled(
	ButtonContainerFullWidth,
)<{
	withBg?: boolean
}>`
	display: flex;
	justify-content: flex-end;
	${({ withBg }) =>
		withBg && `background-color: ${theme.palette.background.default};`}
`

export const EyeBadgeStyle = css`
	border-radius: 0.75rem;
	background: #5c6b82;
	padding-top: ${pxToRem(5)}rem;
	text-align: center;
	color: #fff;
	font-size: ${pxToRem(14)}rem;
	font-style: normal;
	font-weight: 600;
	line-height: 140%;
	letter-spacing: ${pxToRem(-0.42)}rem;
	width: ${pxToRem(44)}rem;
	height: ${pxToRem(28)}rem;
	min-width: ${pxToRem(44)}rem;
	min-height: ${pxToRem(28)}rem;
`

export const PageWrapperStyled = styled.div`
	display: flex;
	flex-direction: column;
	overflow-y: auto;
	flex: 1;
	justify-content: space-between;
`

export const AddButtonWrapper = styled.div`
	width: 100%;
	display: flex;
	justify-content: end;
`

export const ConfigurationPageTitle = styled(Subtitle)`
	color: ${theme.palette.secondary.main};
	white-space: nowrap;
`

export const SwitchControls = styled.div`
	display: flex;
	margin-top: ${pxToRem(13)}rem;

	button {
		font-size: ${pxToRem(14)}rem;
		line-height: ${pxToRem(22)}rem;
	}
`

export const EllipsisOverflow = styled.div`
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
`

export const dialogTitleStyle = css`
	display: flex;
	color: ${theme.palette.common.white};
	margin: 0;
	font-family: 'Poppins', sans-serif;
	font-size: ${theme.typography.pxToRem(18)};
	font-weight: 500;
	background-color: ${theme.palette.violet[900]};
	padding: ${theme.spacing(2, 3)};
	border-top-left-radius: ${theme.spacing(2)};
	border-top-right-radius: ${theme.spacing(2)};
`

export const TitleWrapperWithBorder = styled.div`
	${pageWrapperPadding}
	display: flex;
	justify-content: space-between;
`
