import React from 'react'
import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
} from '../../styleguide/dialog'
import { Trans, useTranslation } from 'react-i18next'
import Button from '../../styleguide/buttons/Button'
import Box from '@mui/material/Box/Box'
import Text from '../../styleguide/typography/Text'
import { PatientDataExport } from '../../model/patient'
import { useDateFormatter } from '../../hooks/useDateFormatter'

type PatientDataExportDialogProps = {
	isOpen: boolean
	onClose: () => void
	onSubmit: () => void
	patient: PatientDataExport
}

type PatientDataCardProps = {
	label: string
	value: string
}

const PatientDataExportDialog = ({
	isOpen,
	onClose,
	onSubmit,
	patient,
}: PatientDataExportDialogProps) => {
	const { t } = useTranslation()
	const { formatDate } = useDateFormatter()

	return (
		<Dialog open={isOpen}>
			<DialogTitle onClose={onClose}>
				{t('exportPatientDataDialog.title')}
			</DialogTitle>
			<DialogContent sx={{ '&.MuiDialogContent-root': { py: 3 } }}>
				<DialogContentText>
					<Box marginBottom={5}>
						<Text variant="body2">
							<Trans i18nKey="exportPatientDataDialog.exportAllData">
								.<strong />
							</Trans>
						</Text>
						<Box
							display="grid"
							gridTemplateColumns="repeat(3, 1fr)"
							gap={2}
							marginTop={2}
						>
							<PatientDataCard
								label={t('exportPatientDataDialog.name')}
								value={patient.name}
							/>
							<PatientDataCard
								label={t('exportPatientDataDialog.surname')}
								value={patient.surname}
							/>
							<PatientDataCard
								label={t('exportPatientDataDialog.dateOfBirth')}
								value={formatDate(patient.birthDate)}
							/>
						</Box>
					</Box>
					<Text variant="body2">
						<Trans i18nKey="exportPatientDataDialog.description">
							.<strong />
						</Trans>
					</Text>
				</DialogContentText>
			</DialogContent>
			<DialogActions justifyContent="space-between">
				<Button variant="outlined" onClick={onClose}>
					{t('exportPatientDataDialog.cancelButton')}
				</Button>
				<Button variant="contained" onClick={onSubmit}>
					{t('exportPatientDataDialog.confirmButton')}
				</Button>
			</DialogActions>
		</Dialog>
	)
}

const PatientDataCard = ({ label, value }: PatientDataCardProps) => {
	return (
		<Box display="flex" flexDirection="column" gap={0.75}>
			<Text variant="caption">{label}</Text>
			<Text variant="body2" fontWeight={500}>
				{value}
			</Text>
		</Box>
	)
}

export default PatientDataExportDialog
