import { utcToZonedTime } from 'date-fns-tz'
import { TFunction } from 'i18next'
import { isEmpty } from 'lodash/fp'
import { DifForm } from '../components/DigitalIntakeForm/types'
import { RetinoscopyData } from '../components/Retinoscopy/models'
import {
	decodeHistoricalRxData,
	decodeInstrumentData,
} from '../decoders/instrumentData'
import { AppointmentType } from '../model/appointment'
import {
	AuxiliaryData,
	CatalogueId,
	ContactLenses,
	ContactLensesCatalogData,
	DigitalIntakeForm,
	Exam,
	ExamApi,
	ExamDocument,
	ExamHistory,
	ExamStatus,
	EyeHealth,
	NextEyeExam,
	OtherTest,
	ReferralFormData,
	StrippedExam,
	WorklistExam,
} from '../model/exam'
import {
	AutorefractionData,
	DayOrNight,
	HistoricalRXData,
	KeratometerData,
	LensDesignType,
	LensMaterialType,
	LensmeterData,
	LensType,
	OCTData,
	PhoropterData,
	PhoropterDataDayOrNight,
	PhoropterDataPart,
	PhoropterDataWithAccuracy,
	PhoropterSingleEye,
	RetinalImageData,
	SlitLampData,
	SlitLampMedia,
	TonometerData,
	VisualFieldsData,
} from '../model/instruments'
import {
	GetInstrumentDataResponseApi,
	InstrumentDataResponseApi,
} from '../model/instrumentsApi'
import {
	BloodPressureData,
	ColorTestData,
	CovertTestData,
	DiagnosticPharmaceuticalTypes,
	FondusMethodsReasonTypes,
	FondusMethodsTypes,
	OcularHealthData,
	PachymetryManualData,
	RetinalImagingPatientRefusalReasonsType,
	StereoTestData,
	TactileTonometryTypes,
	VisualAcuitiesData,
	VitalsData,
} from '../model/manual'
import { Id } from '../model/model'
import { Room, Stage } from '../model/store'
import { ExamTest } from '../model/types'
import {
	clImportAppointments,
	clImportPastDataAppointments,
} from './appointments'
import {
	convertDateToMs,
	FIVE_SECONDS,
	isPastByMoreThanNDays,
	isTodayZoned,
} from './time'
import { FormikHelpers } from 'formik/dist/types'

export const completedExamStatus: ExamStatus[] = [
	'Ended',
	'Interrupted',
	'Uncompleted',
	'LensTrialQuit',
]

export const uncompletedExamStatus: ExamStatus[] = ['Uncompleted']

export const examEndedStatus: ExamStatus[] = completedExamStatus.concat([
	'DoctorEnded',
	'DoctorEndedWithLensTrial',
])

export const isEndedExam = (status: ExamStatus): boolean =>
	completedExamStatus.includes(status)

export const isUncompletedExam = (status: ExamStatus): boolean =>
	uncompletedExamStatus.includes(status)

export const examsDoctorInStore: ExamStatus[] = examEndedStatus.concat([
	'DoctorModeSelected',
	'DoctorStarted',
])

export const examsPreTestStarted: ExamStatus[] = [
	'IntakeFormEnded',
	'PreTestStarted',
	'PreTestFinished',
	'Waiting',
	'Paused',
]

export const tactileTonometryValues: TactileTonometryTypes[] = [
	'Soft',
	'Firm',
	'Hard',
]

export const fondusMethodsReasonValues: FondusMethodsReasonTypes[] = [
	'deferredAppointment',
	'deferredPregnancy',
	'educatedOnOH',
	'patientDeclined',
]

export const ophthalmoloscopyMethodValues: FondusMethodsTypes[] = [
	'undilatedSLE90D',
	'dilatedSLE90D',
	'undilatedSLE78D',
	'dilatedSLE78D',
	'dilatedBIO20D',
	'dilatedSLEDigitalWideFieldLens',
	'undilatedSLEDigitalWideFieldLens',
	'directOphthalmoscopy',
	'panopticOphthalmoscopy',
	'dilatedBIO14D',
	'dilatedBIO22D',
	'scleralDepression',
	'otherTechnique',
]

export const diagnosticPharmaceuticalValues: DiagnosticPharmaceuticalTypes[] = [
	'paremyd',
	'tropicamideHydroxyamphetamine',
	'tropicamide1%',
	'phenylephrine25%',
	'tropicamide05%',
	'fluress',
	'proparacaine',
	'fluoresceinDye',
	'cyclopentolate1%',
	'cyclopentolate05%',
	'tetracaine',
	'roseBengalDye',
	'lissamineGreenDye',
	'atropine1%',
	'cyclopentolate2%',
	'homatropine5%',
]

export const retinalImagingPatientRefusalReasons: RetinalImagingPatientRefusalReasonsType[] =
	[
		'Procedure declined by patient (situation)',
		'Financial problem (finding)',
		'Financial circumstances change (finding)',
		'Patient requests alternative treatment (finding)',
		'Drug declined by patient (situation)',
		'Drug declined by patient - side effects (situation)',
		'Drug declined by patient - patient beliefs (situation)',
		'Drug declined by patient - cannot pay script (situation)',
		'Procedure declined for religious reason (situation)',
		'Patient has moved away (finding)',
		'Patient dissatisfied with result (finding)',
		'Dissatisfied with doctor (finding)',
		'Variable income (finding)',
		'Patient self-discharge against medical advice (procedure)',
		'Drugs not taken/completed (situation)',
		'Family illness (situation)',
		'Patient defaulted from follow-up (finding)',
		'Patient noncompliance - general (situation)',
		'Did not attend (finding)',
		'Further opinion sought (finding)',
		'Treatment delay - patient choice (finding)',
		'Medication declined (situation)',
		'Patient forgets to take medication (finding)',
		'Patient non-compliant - declined intervention / support (situation)',
		'Procedure not wanted (situation)',
		'Income insufficient to buy necessities (finding)',
		'Income sufficient to buy only necessities (finding)',
		'Refused (qualifier value)',
		'Procedure discontinued by patient (situation)',
		'Referral to service declined by subject (situation)',
	]

export const nextExamAssessmentPlanOptions: NextEyeExam[] = [
	'1 month',
	'2 months',
	'3 months',
	'4 months',
	'5 months',
	'6 months',
	'9 months',
	'12 months',
	'13 months',
	'2 weeks',
	'1 week',
	'3 days',
	'2 days',
	'1 day',
	'As Needed',
]

export const convertExamStatusToNumber = (status?: ExamStatus): number => {
	if (!status) {
		return -100
	}

	switch (status) {
		case 'Planned':
			return -1
		case 'noshow':
		case 'confirmed':
		case 'arrived':
		case 'booked':
		case 'cancelled':
		case 'checked-in':
		case 'fulfilled':
		case 'pending':
		case 'proposed':
		case 'waitlist':
		case 'IntakeFormEnded':
			return 0
		case 'PreTestStarted':
			return 1
		case 'PreTestFinished':
			return 2
		// PhoropterSelected, PhoropterFollowUpSelected
		// and PhoropterSelectedSkipRefractionist
		// have been removed after Gemini W1 rework because
		// they are no more used - TEL-8352
		/*case 'PhoropterSelected':
			return 3*/
		case 'RefractionistStarted':
			return 4
		/*case 'PhoropterFollowUpSelected':
		case 'PhoropterSelectedSkipRefractionist':*/
		case 'RefractionistEnded':
			return 5
		case 'DoctorModeSelected':
			return 6
		case 'DoctorStarted':
			return 7
		case 'LensTrialQuit':
		case 'DoctorEndedWithLensTrial':
		case 'DoctorEnded':
			return 8
		case 'Ended':
			return 9
		case 'Paused':
		case 'Waiting':
		case 'Canceled':
		case 'Interrupted':
			return 10
		case 'Uncompleted':
			return 11
		default:
			throw new Error('Unmapped exam status')
	}
}

export const PRE_TEST_MANUAL_DATA_TYPE_VALUES = {
	ARDI: 'arDoctorInterpretation',
	BA: 'binocularAssessment',
	BP: 'bloodPressure',
	CT: 'colorTest',
	OH: 'ocularHealth',
	PY: 'pachymetry',
	RS: 'retinoscopy',
	RX: 'historicalRX',
	ST: 'stereoTest',
	VA: 'visualAcuities',
	VD: 'vitalsData',
}

export const isExamEndedForRefractionist = ({ status, history }: Exam) => {
	const statusNumber = convertExamStatusToNumber(status)
	const examEnded =
		statusNumber >= convertExamStatusToNumber('RefractionistEnded') &&
		statusNumber < convertExamStatusToNumber('Interrupted')
	const examAbortedByRefractionist =
		status === 'Interrupted' &&
		history[history.length - 1].prevStatus === 'RefractionistStarted'
	const examAbortedByDoctor =
		status === 'Interrupted' &&
		history[history.length - 1].prevStatus === 'DoctorStarted'

	return examEnded || examAbortedByRefractionist || examAbortedByDoctor
}

export const getLastStatusChange = (exam: Exam | StrippedExam) =>
	exam.history[exam.history.length - 1]

export const getLastStatusChangeTimeInMs = (exam: Exam | StrippedExam) => {
	const lastStatusChange = getLastStatusChange(exam)

	return lastStatusChange
		? convertDateToMs(lastStatusChange.statusUpdatedAt)
		: 0
}

export const sortByWaitTimeDescending = (
	e1: Exam | StrippedExam,
	e2: Exam | StrippedExam,
) => getLastStatusChangeTimeInMs(e1) - getLastStatusChangeTimeInMs(e2)

export const isLensmeterData = (data: any): data is LensmeterData => {
	const eye = data?.default.OS || data?.default.OD
	return !!eye
}

export const isAutorefractionData = (data: any): data is AutorefractionData => {
	const eye = data?.day.default.eyes.OS || data?.day.default.eyes.OD
	return !!eye
}

export const isVisualFieldsData = (data: any): data is VisualFieldsData =>
	(typeof data === 'object' && data.OS) || data.OD

export const isKeratometerData = (data: any): data is KeratometerData => {
	const eye = data?.day.default.OS || data?.day.default.OD
	return !!eye
}

export const isTonometerData = (data: any): data is TonometerData =>
	typeof data === 'object' &&
	data.OS &&
	data.OD &&
	typeof data.OS === 'object' &&
	typeof data.OD === 'object'

export const isPhoropterData = (data: any): data is PhoropterData => {
	const refraction =
		data?.subjectiveRefraction ||
		data?.finalRefraction ||
		data?.cycloplegicRefraction
	const eye = refraction?.day.default.eyes.OS || refraction?.day.default.eyes.OD
	return !!eye
}

export const isSlitLampData = (data: any): data is SlitLampData =>
	typeof data === 'object' && data.OS && data.OD && typeof data.OS === 'object'

export const isRetinalImageData = (data: any): data is RetinalImageData =>
	typeof data === 'object' && data.OS && data.OD && typeof data.OS === 'object'

export const isOctData = (data: any): data is OCTData =>
	typeof data === 'object' && data.OS && data.OD && typeof data.OS === 'object'

export const getBlankSlitLampData = (): SlitLampData => ({
	OS: {
		data: [],
		note: '',
	},
	OD: {
		data: [],
		note: '',
	},
})

export const getBlankVisualFieldsData = (): VisualFieldsData => ({
	OS: {
		data: [],
		note: '',
	},
	OD: {
		data: [],
		note: '',
	},
})

export const getBlankTonometerData = (): TonometerData => ({
	OD: {
		iop1: '',
		iop2: '',
		iopc: '',
	},
	OS: {
		iop1: '',
		iop2: '',
		iopc: '',
	},
	pachymetry: {
		OD: {
			data: [],
			note: '',
		},
		OS: {
			data: [],
			note: '',
		},
	},
})

export const getBlankAutorefractionData = (): AutorefractionData => ({
	day: {
		default: {
			accuracy: '0.25',
			eyes: {
				OD: {
					sphere: null,
					cyl: null,
					axis: '',
					pupils: '',
				},
				OS: {
					sphere: null,
					cyl: null,
					axis: '',
					pupils: '',
				},
			},
		},
	},
	topography: [],
})

export const getBlankKeratometerData = (): KeratometerData => ({
	day: {
		default: {
			accuracy: '0.25',
			OD: {
				power: '',
				hMeridian: '',
				vPower: '',
				vMeridian: '',
			},
			OS: {
				power: '',
				hMeridian: '',
				vPower: '',
				vMeridian: '',
			},
		},
	},
})

export const getBlankLensometerData = (): LensmeterData => ({
	default: {
		accuracy: '0.25',
		OD: {
			sphere: null,
			cyl: null,
			axis: '',
			add: '',
			hPrism: '',
			hOrient: '',
			vPrism: '',
			vOrient: '',
		},
		OS: {
			sphere: null,
			cyl: null,
			axis: '',
			add: '',
			hPrism: '',
			hOrient: '',
			vPrism: '',
			vOrient: '',
		},
	},
	visualAcuity: {
		OD: {
			distance: '',
			distanceAdditional: '',
			distancePH: '',
			near: '',
			nearAdditional: '',
			nearPH: '',
		},
		OS: {
			distance: '',
			distanceAdditional: '',
			distancePH: '',
			near: '',
			nearAdditional: '',
			nearPH: '',
		},
		BOTH: {
			distance: '',
			distanceAdditional: '',
			near: '',
			nearAdditional: '',
		},
	},
	use: '',
	useNote: '',
	lensType: '',
})

export const getBlankHistoricalRxData = (): HistoricalRXData => {
	return {
		...getBlankLensometerData(),
		lensAddOns: [],
		lensMaterial: '',
		lensDesign: '',
	}
}

export const getBlankRetinalImagingData = (): RetinalImageData => ({
	OS: {
		data: [],
		note: '',
	},
	OD: {
		data: [],
		note: '',
	},
})

export const getBlankOCTData = (): OCTData => ({
	OD: {
		media: [],
		note: '',
	},
	OS: {
		media: [],
		note: '',
	},
})

export const blankPhoropterSingleEye: PhoropterSingleEye = {
	sphere: null,
	cyl: null,
	axis: '',
	add: '',
	hPrism: '',
	hOrient: '',
	vPrism: '',
	vOrient: '',
	description: '',
}

export const blankCLOverRefraction = {
	OS: {
		sphere: '',
		cylinder: '',
		axis: '',
	},
	OD: {
		sphere: '',
		cylinder: '',
		axis: '',
	},
}

const blankPhoropterDataPart: PhoropterDataPart = {
	day: {
		default: {
			accuracy: '0.25',
			eyes: {
				OD: blankPhoropterSingleEye,
				OS: blankPhoropterSingleEye,
			},
			both: {
				distance: '',
				near: '',
			},
			visualAcuity: {
				OD: '',
				OS: '',
			},
		},
	},
	note: '',
	ph: {
		R: '',
		L: '',
		B: '',
	},
	glare: {
		R: '',
		L: '',
		B: '',
	},
}

const blankPhoropterDataPartWithOtherAccuracy: PhoropterDataPart = {
	day: {
		default: {
			accuracy: '0.25',
			eyes: {
				OD: blankPhoropterSingleEye,
				OS: blankPhoropterSingleEye,
			},
			both: {
				distance: '',
				near: '',
			},
			visualAcuity: {
				OD: '',
				OS: '',
			},
		},
		other: {
			accuracy: '0.01',
			eyes: {
				OD: blankPhoropterSingleEye,
				OS: blankPhoropterSingleEye,
			},
			both: {
				distance: '',
				near: '',
			},
			visualAcuity: {
				OD: '',
				OS: '',
			},
		},
	},
	note: '',
	ph: {
		R: '',
		L: '',
		B: '',
	},
	glare: {
		R: '',
		L: '',
		B: '',
	},
}

export const getBlankPhoropterData = (
	withOtherAccuracy: boolean = false,
): {
	subjectiveRefraction: PhoropterDataPart
	cycloplegicRefraction: PhoropterDataPart
	finalRefraction: PhoropterDataPart
	contactLenses: PhoropterDataPart
	pupillaryDistance: string
	sensitivity?: number
} => ({
	subjectiveRefraction: withOtherAccuracy
		? blankPhoropterDataPartWithOtherAccuracy
		: blankPhoropterDataPart,
	cycloplegicRefraction: withOtherAccuracy
		? blankPhoropterDataPartWithOtherAccuracy
		: blankPhoropterDataPart,
	finalRefraction: withOtherAccuracy
		? blankPhoropterDataPartWithOtherAccuracy
		: blankPhoropterDataPart,
	contactLenses: blankPhoropterDataPart,
	pupillaryDistance: '',
	sensitivity: undefined,
})

export const getBlankOcularHealthData = (): OcularHealthData => ({
	chiefComplaint: '',
	slitLampDoctorNote: '',
	retinalImagingDoctorNote: '',
	refuseExamination: false,
	refuseExaminationNote: '',
	videoSliltLamp: {
		OD: {
			lids: '',
			sclera: '',
			bulbconj: '',
			palpebralConj: '',
			cornea: '',
			ac: '',
			angles: '',
			iris: '',
			lens: '',
			note: '',
		},
		OS: {
			lids: '',
			sclera: '',
			bulbconj: '',
			palpebralConj: '',
			cornea: '',
			ac: '',
			angles: '',
			iris: '',
			lens: '',
			note: '',
		},
		note: '',
	},
	opticalNerv: {
		OD: {
			horizcd: '',
			vertcd: '',
			nrrim: '',
			color: '',
			margins: '',
			cupdepth: '',
		},
		OS: {
			horizcd: '',
			vertcd: '',
			nrrim: '',
			color: '',
			margins: '',
			cupdepth: '',
		},
		note: '',
		unableToPerformExam: false,
	},
	fundus: {
		OD: {
			macula: '',
			postpole: '',
			periphery: '',
			vitreus: '',
			avratio: '',
		},
		OS: {
			macula: '',
			postpole: '',
			periphery: '',
			vitreus: '',
			avratio: '',
		},
		note: '',
	},
	gonioscopy: {
		OD: {
			superior: '',
			inferior: '',
			temporal: '',
			nasal: '',
		},
		OS: {
			superior: '',
			inferior: '',
			temporal: '',
			nasal: '',
		},
	},
	goldmanTonometry: {
		OD: {
			iop: '',
			timestamp: '',
		},
		OS: {
			iop: '',
			timestamp: '',
		},
	},
	tactileTonometry: {
		OD: {
			value: '',
			timestamp: '',
		},
		OS: {
			value: '',
			timestamp: '',
		},
	},
	fundusExamMethods: {
		dilated: '',
		methods: [],
		reason: [],
	},
	auxillaryTesting: {
		amslerGrid: {
			OD: {
				notIndicated: true,
				performed: false,
				note: '',
			},
			OS: {
				notIndicated: true,
				performed: false,
				note: '',
			},
		},
		additionalTesting: {
			none: false,
			note: '',
		},
	},
	diagnosticPharmaceutical: {
		diagnostics: [],
		timestamp: '',
	},
	condition: '',
	oct: {
		anteriorSegment: {
			OD: '',
			OS: '',
		},
		opticalNerve: {
			OD: '',
			OS: '',
		},
		retina: {
			OD: '',
			OS: '',
		},
	},
})

export const getBlankPachymetryData = (): PachymetryManualData => ({
	OD: {
		central: '',
		kappaAngle: '',
		nasalAngle: '',
		temporalAngle: '',
		pachy: '',
	},
	OS: {
		central: '',
		kappaAngle: '',
		nasalAngle: '',
		temporalAngle: '',
		pachy: '',
	},
})

export const getBlankRetinoscopyData = (): RetinoscopyData => ({})

export const getBlankVitalsData = (): VitalsData => ({
	notes: '',
	unableToRecordBMIDueToMedicalReasons: false,
	unableToRecordBMIDueToPatientReasons: false,
	unableToRecordBloodPressureForMedicalOrPatientReasons: false,
	bpmin: '',
	bpmax: '',
	leftArmSitting: false,
	rightArmSitting: false,
	wrist: false,
	arm: false,
	bpnotes: '',
})

export const initPreTestData = (
	exam: ExamApi,
	oldExam?: Exam | StrippedExam,
): Exam => ({
	...exam,
	contactLensesTrial: exam.contactLensesTrial?.map(lens => ({
		...lens,
		catalogueId: { OS: lens.catalogueId.L, OD: lens.catalogueId.R },
	})),
	phoropterIsResetting: false,
	discardPhoropterReadingsBefore: 0,
	preTest: {
		instruments: {
			lensmeter: {
				...getBlankLensometerData(),
				...(oldExam && isExam(oldExam)
					? oldExam.preTest.instruments.lensmeter
					: {}),
			},
			autorefraction: {
				...getBlankAutorefractionData(),
				...(oldExam && isExam(oldExam)
					? oldExam.preTest.instruments.autorefraction
					: {}),
			},
			keratometer: {
				...getBlankKeratometerData(),
				...(oldExam && isExam(oldExam)
					? oldExam.preTest.instruments.keratometer
					: {}),
			},
			tonometer: {
				...getBlankTonometerData(),
				...(oldExam && isExam(oldExam)
					? oldExam.preTest.instruments.tonometer
					: {}),
			},
			phoropter: {
				subjectiveRefraction: getBlankPhoropterData().subjectiveRefraction,
				...(oldExam && isExam(oldExam)
					? oldExam.preTest.instruments.phoropter
					: {}),
			},
			visualFields: {
				...getBlankVisualFieldsData(),
				...(oldExam && isExam(oldExam)
					? oldExam.preTest.instruments.visualFields
					: {}),
			},
			slitLamp: {
				...getBlankSlitLampData(),
				...(oldExam && isExam(oldExam)
					? oldExam.preTest.instruments.slitLamp
					: {}),
			},
			retinalImaging: {
				...getBlankRetinalImagingData(),
				...(oldExam && isExam(oldExam)
					? oldExam.preTest.instruments.retinalImaging
					: {}),
			},
			oct: {
				...getBlankOCTData(),
				...(oldExam && isExam(oldExam) ? oldExam.preTest.instruments.oct : {}),
			},
		},
		manual: {
			historicalRx:
				exam.preTest?.historicalRX &&
				exam.preTest?.historicalRX.map(data => {
					return {
						...getBlankHistoricalRxData(),
						...(data && Object.values(data)
							? decodeHistoricalRxData(data)
							: {}),
					}
				}),
			pupil: exam.preTest?.pupil || {},
			visualAcuities: {
				...getBlankVisualAcuitiesData(),
				...(exam.preTest?.visualAcuities || {}),
			},
			stereoTest: {
				...getBlankStereoTestData(),
				...(exam.preTest?.stereoTest || {}),
			},
			colorTest: {
				...getBlankColorTestData(),
				...(exam.preTest?.colorTest || {}),
			},
			bloodPressure: {
				...getBlankBloodPressureData(),
				...(exam.preTest?.bloodPressure || {}),
			},
			ocularHealth: {
				...getBlankOcularHealthData(),
				...(exam.preTest?.ocularHealth || {}),
			},
			pachymetry: {
				...getBlankPachymetryData(),
				...(exam.preTest?.pachymetry || {}),
			},
			binocularAssessment: {
				...getBlankBinocularAssessmentData(),
				...(exam.preTest?.binocularAssessment || {}),
			},
			retinoscopy: {
				...getBlankRetinoscopyData(),
				...exam.preTest?.retinoscopy,
			},
			vitalsData: {
				...getBlankVitalsData(),
				...exam.preTest?.vitalsData,
			},
			arDoctorInterpretation: exam.preTest?.arDoctorInterpretation,
		},
	},
})

export const visualAcuityAdditionalValues = [-2, -1, 1, 2].map(value => ({
	value: value.toString(),
	label: `pretest.visualAcuityAdditionalValues.${value}`,
}))

export const getBlankVisualAcuitiesData = (): VisualAcuitiesData => ({
	aided: {
		OD: {
			distance: '',
			near: '',
		},
		OS: {
			distance: '',
			near: '',
		},
		both: {
			distance: '',
			near: '',
		},
	},
	unaided: {
		OD: {
			distance: '',
			near: '',
		},
		OS: {
			distance: '',
			near: '',
		},
		both: {
			distance: '',
			near: '',
		},
	},
	ph: {
		OD: '',
		OS: '',
		both: '',
	},
	rxWorn: '',
})

export const getBlankStereoTestData = (): StereoTestData => ({
	randot: '',
	secArc: '',
	randomDotPatternsSeen: false,
})

export const getBlankColorTestData = (): ColorTestData => ({
	OD: {
		numerator: '',
		denominator: '',
	},
	OS: {
		numerator: '',
		denominator: '',
	},
})

export const getBlankBloodPressureData = (): BloodPressureData => ({
	bp: {
		min: '',
		max: '',
	},
	leftArmSitting: false,
	rightArmSitting: false,
	wrist: false,
	arm: false,
	note: '',
})

const handleExamTime = (history: ExamHistory): number => {
	let garbageTime = 0
	let arrayToExaminate: ExamHistory = []

	const indexOfLTQuit = history.findIndex(
		h => h.currentStatus === 'LensTrialQuit',
	)
	const indexOfDocEndLT = history.findIndex(
		h => h.currentStatus === 'DoctorEndedWithLensTrial',
	)

	const indexToStartWith = Math.max(indexOfLTQuit, indexOfDocEndLT)

	if (indexToStartWith !== -1 && indexToStartWith !== history.length - 1) {
		arrayToExaminate = history.slice(indexToStartWith, history.length - 1)
	} else {
		arrayToExaminate = history
	}

	for (let i = 0; i < arrayToExaminate.length - 1; i++) {
		if (
			arrayToExaminate[i].currentStatus === 'Paused' ||
			arrayToExaminate[i].currentStatus === 'Waiting'
		) {
			garbageTime =
				garbageTime +
				new Date(arrayToExaminate[i + 1].statusUpdatedAt).getTime() -
				new Date(arrayToExaminate[i].statusUpdatedAt).getTime()
		}
	}

	return garbageTime
}

const calculateStartStep = (
	history: ExamHistory,
):
	| {
			prevStatus: ExamStatus
			currentStatus: ExamStatus
			statusUpdatedAt: Date
	  }
	| undefined => {
	const indexOfLTQuit = history.findIndex(
		h => h.currentStatus === 'LensTrialQuit',
	)
	const indexOfDocEndLT = history.findIndex(
		h => h.currentStatus === 'DoctorEndedWithLensTrial',
	)

	const indexToStartWith = Math.max(indexOfLTQuit, indexOfDocEndLT)

	if (
		indexToStartWith !== -1 &&
		indexToStartWith !== history.length - 1 &&
		history[indexToStartWith + 1].currentStatus !== 'Ended'
	) {
		const lensTrialStep = history[indexToStartWith]
		return lensTrialStep
	}

	const indexOfIntakeFormEnded = history.findIndex(
		h => h.currentStatus === 'IntakeFormEnded',
	)
	if (indexOfIntakeFormEnded === -1) {
		const preTestFinishedStep = history.find(
			step => step.currentStatus === 'PreTestFinished',
		)
		return preTestFinishedStep
	}

	const intakeFormEndedStep = history.find(
		step => step.currentStatus === 'IntakeFormEnded',
	)
	return intakeFormEndedStep
}

const calculateEndStep = (
	history: ExamHistory,
):
	| {
			prevStatus: ExamStatus
			currentStatus: ExamStatus
			statusUpdatedAt: Date
	  }
	| undefined => {
	const indexOfLTQuit = history.findIndex(
		h => h.currentStatus === 'LensTrialQuit',
	)
	const indexOfDocEndLT = history.findIndex(
		h => h.currentStatus === 'DoctorEndedWithLensTrial',
	)

	const indexOfEnded = history.findIndex(h => h.currentStatus === 'Ended')
	const indexToEndWith = Math.max(indexOfLTQuit, indexOfDocEndLT)

	const endedWith =
		(indexToEndWith !== -1 && indexToEndWith === history.length - 1) ||
		(indexOfEnded !== -1 &&
			indexToEndWith !== -1 &&
			indexToEndWith === indexOfEnded - 1)

	if (endedWith) {
		return history[indexToEndWith]
	} else {
		const ended = history.find(step =>
			['Ended', 'Interrupted'].includes(step.currentStatus),
		)
		return (
			ended ||
			history.find(step => ['DoctorEnded'].includes(step.currentStatus))
		)
	}
}

export const calculateResumeStatus = (
	history: Pick<ExamHistory[0], 'currentStatus'>[],
	enableRefractionist: boolean,
): ExamStatus | undefined => {
	const reverseHistory = history.slice().reverse()
	const currentStatus = reverseHistory[0]?.currentStatus
	const lastActiveStatus = reverseHistory.find(
		step => !['Paused', 'Waiting'].includes(step.currentStatus),
	)?.currentStatus
	return currentStatus === 'Paused'
		? lastActiveStatus
		: stateAfterWaiting(enableRefractionist)[lastActiveStatus || '']
}

export const calculateExamDuration = (examOrAppointment?: {
	status: ExamStatus
	history: ExamHistory
	examType?: AppointmentType
}): number => {
	const notEndedYet =
		convertExamStatusToNumber(examOrAppointment?.status) <
		convertExamStatusToNumber('Ended')

	const statusToIgnore = ['LensTrialQuit']

	if (
		notEndedYet &&
		!statusToIgnore.includes(examOrAppointment?.status || '')
	) {
		return 0
	}

	const startStep =
		examOrAppointment?.history && calculateStartStep(examOrAppointment?.history)

	const endStep =
		examOrAppointment?.history && calculateEndStep(examOrAppointment?.history)

	const startTime = new Date(startStep?.statusUpdatedAt || 0).getTime()
	const endTime = new Date(endStep?.statusUpdatedAt || 0).getTime()
	const garbage = examOrAppointment?.history
		? handleExamTime(examOrAppointment?.history)
		: 0

	const total = endTime - startTime - garbage
	return total
}

export const experienceStepsWithRefractionist = {
	checkIn: 0,
	pretest: 1,
	refractionist: 2,
	optometrist: 3,
}

export const experienceStepsWithOutRefractionist = {
	checkIn: 0,
	pretest: 1,
	optometrist: 2,
}

export const remoteImageTaken = (eyeData: {
	data: { path?: string; data?: string }[]
}) => !!eyeData.data.find(({ path, data }) => !!path || !!data)

export const nextStates: { [k in ExamStatus]: ExamStatus[] } = {
	arrived: [],
	booked: [],
	cancelled: [],
	'checked-in': [],
	confirmed: [],
	fulfilled: [],
	noshow: [],
	pending: [],
	proposed: [],
	waitlist: [],
	active: [],
	deleted: [],
	billed: [],
	expired: [],
	missed: [],
	'entered-in-error': [],
	Planned: [],
	IntakeFormEnded: ['PreTestStarted', 'Interrupted'],
	PreTestStarted: [
		'PreTestFinished',
		'Interrupted',
		'Paused',
		'Waiting',
		'RefractionistStarted',
		'DoctorModeSelected',
	],
	PreTestFinished: [
		'RefractionistStarted',
		'DoctorModeSelected',
		'Waiting',
		'Interrupted',
		'Waiting',
	],
	RefractionistStarted: ['RefractionistEnded', 'Interrupted'],
	RefractionistEnded: ['DoctorModeSelected', 'Interrupted'],
	DoctorModeSelected: ['DoctorStarted', 'Interrupted', 'Waiting'],
	DoctorStarted: [
		'DoctorEnded',
		'LensTrialQuit',
		'DoctorEndedWithLensTrial',
		'Interrupted',
		'Paused',
		'Waiting',
	],
	DoctorEnded: ['Ended'],
	LensTrialQuit: ['DoctorModeSelected', 'Interrupted', 'Ended'],
	DoctorEndedWithLensTrial: ['DoctorModeSelected', 'Interrupted', 'Ended'],
	Ended: [],
	Paused: [
		'PreTestStarted',
		'RefractionistStarted',
		'DoctorStarted',
		'Interrupted',
		'Waiting',
	],
	Waiting: [
		'IntakeFormEnded',
		'PreTestFinished',
		'RefractionistEnded',
		'DoctorEnded',
		'Interrupted',
	],
	Interrupted: [],
	Canceled: [],
	Uncompleted: [],
}

export const stateAfterWaiting = (
	enableRefractionist: boolean,
): { [key: string]: ExamStatus } => ({
	Upcoming: 'DoctorModeSelected',
	IntakeFormEnded: 'IntakeFormEnded',
	PreTestStarted: 'IntakeFormEnded',
	PreTestFinished: 'PreTestFinished',
	RefractionistStarted: 'PreTestFinished',
	RefractionistEnded: 'RefractionistEnded',
	DoctorModeSelected: enableRefractionist
		? 'RefractionistEnded'
		: 'PreTestFinished',
	DoctorStarted: enableRefractionist ? 'RefractionistEnded' : 'PreTestFinished',
	DoctorEnded: 'DoctorEnded',
	Ended: 'DoctorEnded',
})

export const getStatusOptions = (
	exam: { status: ExamStatus; history: { currentStatus: ExamStatus }[] },
	isLocalTechnician: boolean,
	t: (key: string) => string,
	isLocalDoctor: boolean,
	enableRefractionist: boolean = false,
): {
	label: string
	value?: ExamStatus
}[] => {
	if (!exam.status) {
		return []
	}

	const isResuming = exam.status === 'Paused' || exam.status === 'Waiting'

	const options: { label: string; value: ExamStatus }[] = []

	if (
		(isLocalTechnician || isLocalDoctor) &&
		nextStates[exam.status].find(el => el === 'Waiting')
	) {
		options.push({
			label: t('app.statusSelect.Waiting'),
			value: 'Waiting',
		})
	}

	if (isResuming) {
		return [
			{
				label: t('app.statusSelect.resume'),
				value: calculateResumeStatus(exam.history, enableRefractionist),
			},
			...options,
		]
	}

	if (nextStates[exam.status].find(el => el === 'Paused')) {
		options.push({
			label: t('app.statusSelect.Pause'),
			value: 'Paused',
		})
	}
	if (nextStates[exam.status].find(el => el === 'Interrupted')) {
		options.push({
			label: t('app.statusSelect.stop'),
			value: 'Interrupted',
		})
	}
	if (['DoctorEnded', 'DoctorEndedWithLensTrial'].includes(exam.status)) {
		options.push({
			label: t('app.statusSelect.Ended'),
			value: 'Ended',
		})
	}

	return options
}

const getPhoropterDataOrUndefined = (
	dayOrNight: DayOrNight,
	precision: keyof PhoropterDataDayOrNight,
	phoropterDataPart?: PhoropterDataPart,
) => {
	const data = phoropterDataPart?.[dayOrNight]?.[precision]
	const anyDataOD = Object.values(data?.eyes.OD || {}).some(value => !!value)
	const anyDataOS = Object.values(data?.eyes.OS || {}).some(value => !!value)
	return anyDataOD || anyDataOS ? data : undefined
}

export const getDataWithPrecision = (
	dayOrNight: DayOrNight,
	precision: keyof PhoropterDataDayOrNight,
	exam?: Exam,
) => {
	if (!exam) {
		return undefined
	}

	const contactLensesOrSubjectiveRefraction =
		getPhoropterDataOrUndefined(
			dayOrNight,
			precision,
			exam.preTest.instruments.phoropter.contactLenses,
		) ||
		getPhoropterDataOrUndefined(
			dayOrNight,
			precision,
			exam.preTest.instruments.phoropter.finalRefraction,
		) ||
		getPhoropterDataOrUndefined(
			dayOrNight,
			precision,
			exam.preTest.instruments.phoropter.subjectiveRefraction,
		)
	return contactLensesOrSubjectiveRefraction
}

export const setClDataInFormFromPhoropter = async ({
	setFieldValue,
	dataPath,
	exam,
	eye = 'OD',
	dayOrNight = 'day',
	precision = 'default',
}: {
	setFieldValue: FormikHelpers<unknown>['setFieldValue']
	exam?: Exam
	dataPath: string
	eye?: keyof PhoropterDataWithAccuracy['eyes']
	dayOrNight?: DayOrNight
	precision?: 'default' | 'other'
}) => {
	const dataWithPrecision = getDataWithPrecision(dayOrNight, precision, exam)

	await setFieldValue(
		`${dataPath}.sphere`,
		dataWithPrecision?.eyes[eye].sphere || null,
	)
	await setFieldValue(
		`${dataPath}.cylinder`,
		dataWithPrecision?.eyes[eye].cyl || null,
	)
	await setFieldValue(
		`${dataPath}.axis`,
		dataWithPrecision?.eyes[eye].axis || '',
	)
	await setFieldValue(
		`${dataPath}.addition`,
		dataWithPrecision?.eyes[eye].add || '',
	)
}

export const getInitialDataForContactLenses = (): ContactLenses => ({
	lensType: {
		OD: '',
		OS: '',
	},
	manufacturer: {
		OD: '',
		OS: '',
	},
	suggestedCatalogueId: {
		OD: '',
		OS: '',
	},
	catalogueId: {
		OD: '',
		OS: '',
	},
	status: 'NONE',
	trialType: undefined,
	modality: undefined,
	measure: {
		OS: {
			baseCurve: '',
			diameter: '',
			sphere: '',
			cylinder: '',
			axis: '',
			addition: '',
			description: undefined,
		},
		OD: {
			baseCurve: '',
			diameter: '',
			sphere: '',
			cylinder: '',
			axis: '',
			addition: '',
			description: undefined,
		},
	},
	aidedVA: {
		OS: {
			distance: '',
			near: '',
		},
		OD: {
			distance: '',
			near: '',
		},
		OU: {
			distance: '',
			near: '',
		},
	},
	overRefraction: blankCLOverRefraction,
	evaluation: {
		OS: {
			movement: '',
			alignment: '',
			centration: '',
			rotation: '',
			comfort: '',
			rotationDirection: '',
			coverage: undefined,
		},
		OD: {
			movement: '',
			alignment: '',
			centration: '',
			rotation: '',
			comfort: '',
			rotationDirection: '',
			coverage: undefined,
		},
	},
	notes: '',
	newSlExamInProgress: false,
	selectedEye: 'BIN',
})

const getInitialDataForContactLensesArray = (): ContactLenses[] =>
	new Array(6).fill(getInitialDataForContactLenses())

export const padLensTrialData = (
	lensTrialData: ContactLenses[],
): ContactLenses[] => {
	const defaultData = getInitialDataForContactLensesArray()
	return defaultData.map((data, index) => lensTrialData[index] || data)
}

export const getExamTime = (history: ExamHistory): number => {
	const arrayToExaminate: ExamHistory = []
	let pauseTime = 0
	let waitingTime = 0

	for (let k = 0; k < history.length - 1; k++) {
		if (
			history[k].currentStatus === history[history.length - 1].currentStatus ||
			history[k].currentStatus === 'Paused' ||
			history[k].currentStatus === 'Waiting'
		) {
			arrayToExaminate.push(history[k])
		}
	}
	if (arrayToExaminate.length === 0) return 0

	const totalTime =
		new Date(
			arrayToExaminate[arrayToExaminate.length - 1].statusUpdatedAt,
		).getTime() - new Date(arrayToExaminate[0].statusUpdatedAt).getTime()

	for (let i = 0; i < arrayToExaminate.length - 1; i++) {
		if (arrayToExaminate[i].currentStatus === 'Paused') {
			pauseTime =
				pauseTime +
				new Date(arrayToExaminate[i + 1].statusUpdatedAt).getTime() -
				new Date(arrayToExaminate[i].statusUpdatedAt).getTime()
		}
		if (arrayToExaminate[i].currentStatus === 'Waiting') {
			waitingTime =
				waitingTime +
				new Date(arrayToExaminate[i + 1].statusUpdatedAt).getTime() -
				new Date(arrayToExaminate[i].statusUpdatedAt).getTime()
		}
	}
	const effectiveDuration = totalTime - pauseTime - waitingTime
	return effectiveDuration
}

export const generateLensName = (cl: ContactLensesCatalogData) => {
	const { custom, lensType, name, brand } = cl

	if (custom) {
		return `Custom Product - ${lensType}`
	} else if (!name && !brand) {
		return name
	} else if (!name) {
		return brand
	} else {
		return `${name} - ${brand}`
	}
}

export const filterSlitLampData = (
	slitLampData: SlitLampData | undefined,
	contactLensesSection: boolean,
	keepOnlyLastNDays?: number,
	selectedContactLensId?: CatalogueId,
) => {
	const filterDataCallback = (
		{ createdAt, contactLensId }: SlitLampMedia,
		eye: 'OD' | 'OS',
	) => {
		// Ocular Health section
		if (
			contactLensesSection === false &&
			selectedContactLensId === undefined &&
			(contactLensId === undefined || contactLensId.length === 0)
		) {
			return true
		}

		// Contact Lenses
		const IsNotOutdatedMedia =
			keepOnlyLastNDays === undefined ||
			!createdAt ||
			!isPastByMoreThanNDays(createdAt, keepOnlyLastNDays)

		const IsMediaForSelectedCL =
			contactLensesSection === true &&
			selectedContactLensId !== undefined &&
			selectedContactLensId[eye] !== '' &&
			selectedContactLensId[eye] === contactLensId

		return IsNotOutdatedMedia && IsMediaForSelectedCL
	}

	const slitLampDataToShow = slitLampData
		? {
				OS: {
					data: slitLampData.OS.data.filter(data =>
						filterDataCallback(data, 'OS'),
					),
					note: slitLampData.OS.note,
				},
				OD: {
					data: slitLampData.OD.data.filter(data =>
						filterDataCallback(data, 'OD'),
					),
					note: slitLampData.OD.note,
				},
		  }
		: undefined

	return slitLampDataToShow
}

export const filterSlitLampDataOld = (
	slitLampData: SlitLampData | undefined,
	statusToKeep: ExamStatus | ExamStatus[],
	keepOnlyLastNDays?: number,
	selectedContactLensId?: CatalogueId,
	isClPage = false,
) => {
	const slitLampDataToShow = slitLampData
		? {
				OS: {
					data: slitLampData.OS.data
						.filter(
							({ createdAt }) =>
								keepOnlyLastNDays === undefined ||
								!createdAt ||
								!isPastByMoreThanNDays(createdAt, keepOnlyLastNDays),
						)
						.filter(({ contactLensId }) => {
							if (!isClPage) return true

							return (
								!!contactLensId &&
								(!selectedContactLensId ||
									selectedContactLensId.OS === contactLensId)
							)
						}),
					note: slitLampData.OS.note,
				},
				OD: {
					data: slitLampData.OD.data
						.filter(
							({ createdAt }) =>
								keepOnlyLastNDays === undefined ||
								!createdAt ||
								!isPastByMoreThanNDays(createdAt, keepOnlyLastNDays),
						)
						.filter(({ contactLensId }) => {
							if (!isClPage) return true

							return (
								!!contactLensId &&
								(!selectedContactLensId ||
									selectedContactLensId.OD === contactLensId)
							)
						}),
					note: slitLampData.OD.note,
				},
		  }
		: undefined

	return slitLampDataToShow
}

export const getVideoSlitLampNormalValues = (values: OcularHealthData) => ({
	OS: {
		lids: 'WNL',
		sclera: 'White & Quiet',
		bulbconj: 'Clear',
		palpebralConj: 'Clear',
		cornea: 'Clear',
		ac: 'Deep & Quiet',
		angles: '4+',
		iris: 'Flat & Clear',
		lens: 'Clear',
		note: values.videoSliltLamp.OS?.note,
	},
	OD: {
		lids: 'WNL',
		sclera: 'White & Quiet',
		bulbconj: 'Clear',
		palpebralConj: 'Clear',
		cornea: 'Clear',
		ac: 'Deep & Quiet',
		angles: '4+',
		iris: 'Flat & Clear',
		lens: 'Clear',
		note: values.videoSliltLamp.OD?.note,
	},
	note: values.videoSliltLamp.note,
})

export const getOpticalNerveNormalValues = () => ({
	OS: {
		horizcd: '0.30',
		vertcd: '0.30',
		nrrim: 'WNL',
		color: 'Pink',
		margins: 'Distinct 360',
		cupdepth: 'Normal',
	},
	OD: {
		horizcd: '0.30',
		vertcd: '0.30',
		nrrim: 'WNL',
		color: 'Pink',
		margins: 'Distinct 360',
		cupdepth: 'Normal',
	},
	note: '',
})

export const getFundusNormalValues = () => ({
	OS: {
		macula: 'Flat',
		postpole: 'Clear',
		periphery: 'Flat, Clear, No Holes/Breaks 360',
		vitreus: 'Clear',
		avratio: '2/3',
	},
	OD: {
		macula: 'Flat',
		postpole: 'Clear',
		periphery: 'Flat, Clear, No Holes/Breaks 360',
		vitreus: 'Clear',
		avratio: '2/3',
	},
	note: '',
})

export const getMinutesSinceCheckIn = (exam: Pick<Exam, 'history'>) => {
	const examStarted = exam.history.find(
		exam => exam.currentStatus === 'IntakeFormEnded',
	)
	if (!examStarted) return
	const checkInTime = new Date(examStarted.statusUpdatedAt).getTime()
	const delta = new Date().getTime() - checkInTime
	const minutes = delta / (1000 * 60)
	return Math.round(minutes)
}

export const shouldImportOldExamData = (type?: AppointmentType) =>
	type
		? clImportPastDataAppointments.includes(type) ||
		  clImportAppointments.includes(type)
		: false

export const getBlankEyeHealthForm = ({
	recommendations,
	nextEyeExam,
}: {
	recommendations?: string
	nextEyeExam?: NextEyeExam
}): EyeHealth => ({
	diagnosis: {
		cornea: [
			{
				diagnosi: 'Healthy Cornea / Lenses', // match with MongoDB value
				note: 'Corneas and lenses appear healthy.', // match with MongoDB value
			},
		],
		lidsConjunctiva: [
			{
				diagnosi: 'Healthy Lids / Conjunctiva', // match with MongoDB value
				note: 'Lids and Conjunctiva appear healthy.', // match with MongoDB value
			},
		],
		retina: [
			{
				diagnosi: 'Healthy Retina/ Optica Nerve / Blood Vessels', // match with MongoDB value
				note: 'Retinas, optic nerves, and blood vessels appear healthy.', // match with MongoDB value
			},
		],
	},
	recommendations: recommendations || '',
	nextEyeExam: nextEyeExam || '12 months',
	medicalHistory: [],
})

const defaultCoverTestData: CovertTestData = {
	horizontal: '',
	verticalOD: '',
	verticalOS: '',
	horizontalOD: '',
	horizontalOS: '',
	horizontalBase: '',
	verticalODBase: '',
	verticalOSBase: '',
	horizontalODBase: '',
	horizontalOSBase: '',
	note: '',
}

export const getBlankBinocularAssessmentData = () => ({
	pupil: {
		OD: {
			perrla: false,
			apd: '',
			photopic: '',
			scotopic: '',
			mesopic: '',
			directResponse: '',
		},
		OS: {
			perrla: false,
			apd: '',
			photopic: '',
			scotopic: '',
			mesopic: '',
			directResponse: '',
		},
		reactiveAccomodation: false,
	},
	extraocular: {
		OD: {
			from: false,
			restrictions: false,
		},
		OS: {
			from: false,
			restrictions: false,
		},
		notes: '',
	},
	phoria: {
		horizontalNear: {
			value: '',
		},
		horizontalDistance: {
			value: '',
		},
		verticalNear: {
			value: '',
		},
		verticalDistance: {
			value: '',
		},
	},
	stereopsys: {
		stereopsys: '',
		distance: '',
		near: '',
		note: '',
	},
	nraPra: {
		nraBlur: '',
		nraRecovery: '',
		praBlur: '',
		praRecovery: '',
		note: '',
	},
	fusion: {
		fusion: '',
		distance: '',
		distanceImpression: '',
		nearImpression: '',
		near: '',
		note: '',
	},
	coverTestNear: { ...defaultCoverTestData },
	coverTestDistance: { ...defaultCoverTestData },
	aniseikonia: {
		distanceHorizontal: '',
		nearHorizontal: '',
		note: '',
	},
	acaRatio: {
		ratio: '',
	},
	npc: {
		blur: '',
		break: '',
		recovery: '',
		note: '',
	},
	npa: {
		OD: '',
		OS: '',
		BIN: '',
	},
	dominance: {
		eyeDominance: '',
	},
})

export const getAuxiliaryInitialValues = (): AuxiliaryData => {
	return {
		pursuitsAndSaccades: {
			OD: {
				safe: false,
				abnormal: false,
				notes: '',
			},
			OS: {
				safe: false,
				abnormal: false,
				notes: '',
			},
		},
		vergeancesHorizontal: {
			bi: {
				dist: {
					blur: '',
					break: '',
					recovery: '',
				},
				near: {
					blur: '',
					break: '',
					recovery: '',
				},
			},
			bo: {
				dist: {
					blur: '',
					break: '',
					recovery: '',
				},
				near: {
					blur: '',
					break: '',
					recovery: '',
				},
			},
		},
		vergeancesVertical: {
			buOS: {
				break: '',
				recovery: '',
			},
			buOD: {
				break: '',
				recovery: '',
			},
			bdOS: {
				break: '',
				recovery: '',
			},
			bdOD: {
				break: '',
				recovery: '',
			},
		},
	}
}

export const DIF_INITIAL_VALUES = {
	address: '',
	age: '',
	allergiesEntry: '',
	assistanceReason: '',
	assistanceRequirements: undefined,
	assistanceRequirementsText: '',
	cellPhone: '',
	city: '',
	contactLensInterest: '',
	contactLensType: '',
	email: '',
	eyeDiseaseEntry: '',
	firstName: '',
	genderOther: '',
	homePhone: '',
	insuranceProvider: undefined,
	lastName: '',
	lastSSN: '',
	medicalConditionEntry: '',
	medicalConditionsEntry: '',
	medicalInsuranceMemberId: '',
	medicalInsuranceProvider: '',
	medicationsEntry: '',
	memberId: '',
	frailtySymptoms: [],
	snomedStatuses: undefined,
	housingStatus: {
		value: '',
		label: '',
	},
	patientCare: {
		value: '',
		label: '',
	},
	patientEquipment: [],
	prescriptionYear: '',
	primaryMember: '',
	primaryMemberFirstName: '',
	primaryMemberLastName: '',
	primaryMemberSSN: '',
	state: '',
	tobaccoEndDate: undefined,
	zipCode: '',
	SymptompsCode: '',
}

const EXAM_DIF_FORM_INITIAL_VALUES: Partial<Record<keyof DifForm, unknown>> = {
	healthConditions: [],
	currentEyewear: [],
	visionIssues: [],
	hobbies: [],
	workEnvironment: [],
}

export const fillExamDifData = (
	exam: Exam,
): Exam & { dif: Record<keyof DifForm | PropertyKey, unknown> } => {
	// TEL-9419: we need to fill the exam dif form with the initial values to prevent the form from breaking because of null values
	const examDif: Partial<DifForm> = Object.entries(exam.dif || {}).reduce(
		(accumulator, [key, value]) => {
			return {
				...accumulator,
				[key]:
					typeof value !== 'boolean'
						? value || EXAM_DIF_FORM_INITIAL_VALUES[key as keyof DifForm] || ''
						: value,
			}
		},
		{},
	)
	return {
		...exam,
		dif: { ...DIF_INITIAL_VALUES, ...examDif },
	}
}

export const getGenderFormDigitalIntakeForm = (dif?: DigitalIntakeForm) => {
	if (!dif) {
		return ''
	}

	switch (dif.gender) {
		case 'MALE':
			return 'male'
		case 'FEMALE':
			return 'female'
		default:
			return 'notAnswer'
	}
}

export const getExtraDataFromDIF = (dif?: DigitalIntakeForm, exam?: Exam) => {
	const eyeHealth =
		exam && exam.eyeHealth ? exam.eyeHealth : getBlankEyeHealthForm({})
	return {
		gender: getGenderFormDigitalIntakeForm(dif),
		eyeHealth: {
			...eyeHealth,
			medicalHistory: dif ? dif.healthConditions : undefined,
		},
	}
}

export const getBlankReferralForm = (
	ph?: PhoropterDataPart,
): ReferralFormData => ({
	specialty: '',
	referralDoctor: '',
	urgent: false,
	referralReason: '',
	referralReasonNote: '',
	medicalValues: ph,
})

export const isStrippedExam = (
	exam: Exam | ExamApi | StrippedExam,
): exam is StrippedExam => {
	return 'isStripped' in exam && !!exam.isStripped
}

export const isExam = (
	exam: Exam | ExamApi | StrippedExam | WorklistExam | undefined,
): exam is Exam => {
	return exam !== undefined && !('isStripped' in exam)
}

export const getIsExamDocument = (
	doc: Record<string, any>,
): doc is ExamDocument => 'fileName' in doc && 'ref' in doc

export const filterExamFromStripped = (
	exams: (Exam | StrippedExam)[],
): Exam[] => {
	return exams.filter(isExam)
}

export const isExamOpenForDoctor = (exam?: Exam | StrippedExam) => {
	if (!exam) {
		return false
	}

	const examOpenForDoctorStatus: ExamStatus[] = ['DoctorStarted', 'DoctorEnded']
	return examOpenForDoctorStatus.includes(exam.status)
}

export const lastExamDoctor = (exam?: Exam | StrippedExam) => {
	return exam
		? [...(exam.historyDoctor || [])].pop()?.doctor || exam.doctor
		: undefined
}

export const isAssegnatedToDoctor = (exam: Exam | StrippedExam) =>
	convertExamStatusToNumber(exam.status) >=
		convertExamStatusToNumber('RefractionistEnded') && exam.status !== 'Waiting'

export const isExamCallable = (exam: Exam | StrippedExam) => {
	if (!isExam(exam)) {
		return false
	}

	const callableExamStatus: ExamStatus[] = [
		'Paused',
		'RefractionistStarted',
		'DoctorStarted',
		'DoctorEnded',
		'DoctorEndedWithLensTrial',
	]

	if (!callableExamStatus.includes(exam.status)) {
		return false
	}

	if (exam.mode !== 'remote') {
		return false
	}

	return true
}

export const getRemoteRooms = (
	exams: WorklistExam[],
	stages: Stage[],
	username: string,
): Room[] => {
	const filteredExams = exams
		.filter(
			exam =>
				exam.localTechnician === username &&
				!examEndedStatus.includes(exam.status),
		)
		.map(exam => exam._id)

	const rooms = stages.flatMap(stage => {
		const roomsLocked = stage.rooms.filter(room => {
			return (
				filteredExams.includes(room.lockedByExamId) && !!room.azureCommunication
			)
		})

		const roomsLockedByRemoteExams = roomsLocked.filter(room => {
			const exam = exams.find(e => e._id === room.lockedByExamId)
			if (exam && exam.mode === 'remote') {
				return true
			}
			return false
		})
		return roomsLockedByRemoteExams
	})

	return rooms
}

export const getClTrialValuesToSubmit = (
	values: ContactLenses[],
	activeTabIndex: number,
	doctorUsername: string,
): ContactLenses[] =>
	values.map((value, tabIndex) => ({
		...value,
		doctorUsername:
			tabIndex === activeTabIndex ? doctorUsername : value.doctorUsername,
	}))

export const hasNewPrescribedCL = (
	newValues: ContactLenses[],
	prevValues: ContactLenses[],
) =>
	newValues.some(
		(cl, index) =>
			(cl.status === 'PRESCRIBED' ||
				prevValues[index].status === 'PRESCRIBED') &&
			cl.status !== prevValues[index].status,
	)

export const hasNewTrialCL = (
	newValues: ContactLenses[],
	prevValues: ContactLenses[],
) =>
	newValues.some(
		(cl, index) =>
			cl.status === 'TRIAL' && cl.status !== prevValues[index].status,
	)

export const isNewlyAssigned = (
	examHistory: ExamHistory | undefined,
	timeZone: string | undefined,
): boolean => {
	if (!examHistory || !timeZone) {
		return false
	}

	const nowTime = utcToZonedTime(new Date(), timeZone)
	const { statusUpdatedAt } = examHistory[examHistory.length - 1]
	const lastStatusUpdate = utcToZonedTime(new Date(statusUpdatedAt), timeZone)
	const milliseconds = nowTime.getTime() - lastStatusUpdate.getTime()

	return milliseconds < FIVE_SECONDS
}

export const getExamChangerUrl = ({
	selectedExamId,
	activeExamId,
	storeId,
	patientId,
	isDoctor,
	isFrontDesk,
}: {
	selectedExamId: Id
	activeExamId: Id
	storeId?: Id
	patientId: string
	isDoctor: boolean
	isFrontDesk: boolean
}) => {
	const shouldReturnToActiveExamPage = selectedExamId === activeExamId

	if (shouldReturnToActiveExamPage) {
		const activeExamUrl =
			isDoctor || isFrontDesk
				? `/doctor/exam/${activeExamId}`
				: `/store/${storeId}/patients/${patientId}/exams/${activeExamId}/0`

		return activeExamUrl
	}

	const currentExamUrl =
		isDoctor || isFrontDesk
			? `/doctor/exam/${selectedExamId}/current/${activeExamId}`
			: `/store/${storeId}/patients/${patientId}/exams/${selectedExamId}/current/${activeExamId}/0`

	return currentExamUrl
}

export const getBlankOtherTestData = (): OtherTest => ({
	code: '',
	name: '',
	notes: '',
	images: [],
	attachment: undefined,
})

export const getGonioscopyOptions = (t: TFunction) => [
	{
		value: 'noStructuresVisible',
		label: t('doctor.ocularHealthSection.gonioscopy.noStructuresVisible'),
	},
	{
		value: 'trabecularMeshwork',
		label: t('doctor.ocularHealthSection.gonioscopy.trabecularMeshwork'),
	},
	{
		value: 'scleralSpur',
		label: t('doctor.ocularHealthSection.gonioscopy.scleralSpur'),
	},
	{
		value: 'ciliaryBody',
		label: t('doctor.ocularHealthSection.gonioscopy.ciliaryBody'),
	},
]

export const getCLSpecialtyLens = (t: TFunction) => [
	{
		value: 'RGP',
		label: t('lensesTrial.specialtyLens.rgp'),
	},
	{
		value: 'Scleral',
		label: t('lensesTrial.specialtyLens.scleral'),
	},
	{
		value: 'OrthoK',
		label: t('lensesTrial.specialtyLens.orthoK'),
	},
	{
		value: 'Hybrid',
		label: t('lensesTrial.specialtyLens.hybrid'),
	},
]

export const getOtherTestOptions = () => {
	return [
		{
			value: 'tearTest',
			label: 'Tear test',
		},
		{
			value: 'vitalDye',
			label: 'Vital Dye',
		},
		{
			value: 'beginOasisTears',
			label: 'Begin Oasis Tears - Discussed Punctal Plugs',
		},
		{
			value: 'glareAcuityTest',
			label: 'Glare Acuity Test',
		},
		{
			value: 'hirschbergTest',
			label: 'Hirschberg test',
		},
		{
			value: 'PAM',
			label: 'PAM',
		},
		{
			value: 'vonHerrickAngles',
			label: 'Von Herrick Angles; 4+ open OU N&T',
		},
		{
			value: 'scanningAnteriorSegment',
			label:
				'Scanning Computerized Ophthalmic Imaging of the Anterior Segment - 92132',
		},
		{
			value: 'scanningOpticNerve',
			label:
				'Scanning Computerized Ophthalmic Imaging of the Optic Nerve - 92133',
		},
		{
			value: 'scanningRetina',
			label: 'Scanning Computerized Ophthalmic Imaging of the retina - 92134',
		},
		{
			value: 'visualFieldExaminationBrief',
			label: 'Visual Field Examination, Brief - 92081',
		},
		{
			value: 'visualFieldExaminationInt',
			label: 'Visual Field Examination, Int - 92082',
		},
		{
			value: 'visualFieldExaminationComp',
			label: 'Visual Field Examination, Comp - 92083',
		},
		{
			value: 'pachymetry',
			label: 'Pachymetry - 76514',
		},
		{
			value: 'cornealTopography ',
			label: 'Corneal topography - 92025',
		},
		{
			value: 'medicalPhotos ',
			label: 'Medical Photos - 92250',
		},
	]
}

export const getPHDataFromExam = (
	instrumentData?: InstrumentDataResponseApi | GetInstrumentDataResponseApi,
) => {
	const data =
		instrumentData && !isEmpty(instrumentData?.examData)
			? decodeInstrumentData(
					instrumentData as GetInstrumentDataResponseApi,
					'PH',
			  )
			: undefined

	return (
		(data as PhoropterData)?.finalRefraction?.day ||
		(data as PhoropterData)?.subjectiveRefraction?.day ||
		getBlankPhoropterData().finalRefraction.day
	)
}

export const isRxCkExam = (exam: Exam | StrippedExam | undefined): boolean => {
	const RxCkExamTypes: AppointmentType[] = ['RX CK']
	return !!exam && !!exam.examType && exam?.examType === RxCkExamTypes[0]
}

export const sortExamByCreationDateDesc = (
	exams: (Exam | ExamApi | StrippedExam)[],
) => {
	return exams.sort(
		(a, b) =>
			Date.parse((b.checkedInAt || b.externalAppointment.date).toString()) -
			Date.parse((a.checkedInAt || a.externalAppointment.date).toString()),
	)
}

export const sortExamByCreationDateAsc = (exams: (Exam | StrippedExam)[]) => {
	return exams.sort(
		(a, b) =>
			Date.parse((a.checkedInAt || a.externalAppointment.date).toString()) -
			Date.parse((b.checkedInAt || b.externalAppointment.date).toString()),
	)
}

export const returnGraphsExams = (
	exams: (Exam | StrippedExam)[],
	phDatas: {
		[k: string]: InstrumentDataResponseApi
	},
): (Exam | StrippedExam)[] => {
	// in the graph exam we have to see only exams that have some values in final refraction
	const filteredExamsByExamData = exams.filter(exam => {
		const data =
			phDatas[exam._id] && !isEmpty(phDatas[exam._id]?.examData)
				? decodeInstrumentData(
						phDatas[exam._id] as GetInstrumentDataResponseApi,
						'PH',
				  )
				: undefined

		const finalRefractionCheck = !!(
			(data as PhoropterData)?.finalRefraction &&
			(data as PhoropterData)?.finalRefraction?.day
		)
		return finalRefractionCheck
	})

	if (filteredExamsByExamData.length <= 5) {
		return filteredExamsByExamData.reverse()
	}

	// exams history has to respect this rule:
	// to generate the graphs we should take into consideration the 1st exam with
	// final refraction completed (no matter the status) and the last 4 exams (if available) with
	// final refraction completed (no matter the status)
	let result: (Exam | StrippedExam)[] = []
	result.push(filteredExamsByExamData[filteredExamsByExamData.length - 1])
	const otherLastExams = filteredExamsByExamData.slice(0, 4).reverse()
	result = result.concat(otherLastExams)

	return result
}

export const lensTrialKeys: LensType[] = [
	'SingleVisionDistance',
	'Progressive',
	'Bifocal',
	'Computer',
	'SingleVisionNear',
	'Trifocal',
	'Sunglasses',
]

export const lensDesignKeys: LensDesignType[] = [
	'aspheric',
	'standard',
	'standard-7x28',
	'standard-8x35',
]

export const lensMaterialKeys: LensMaterialType[] = [
	'cr39',
	'glass',
	'hi-index',
	'polycarbonate',
	'trivex',
]

export const getRefractionSection = (
	refractionData: PhoropterData | undefined,
): keyof PhoropterData => {
	if (!refractionData) {
		return 'subjectiveRefraction'
	}

	const eyes: ('OD' | 'OS')[] = ['OD', 'OS']

	let isSubjective = false
	let isCyclopedic = false
	let isFinal = false
	eyes.forEach(eye => {
		const obj = refractionData.subjectiveRefraction?.day.default.eyes
		obj &&
			Object.keys(obj[eye]).forEach(key => {
				isSubjective =
					isSubjective || !isEmpty(obj[eye][key as keyof PhoropterSingleEye])
			})
	})

	eyes.forEach(eye => {
		const obj = refractionData.cycloplegicRefraction?.day.default.eyes
		obj &&
			Object.keys(obj[eye]).forEach(key => {
				isCyclopedic =
					isCyclopedic || !isEmpty(obj[eye][key as keyof PhoropterSingleEye])
			})
	})

	eyes.forEach(eye => {
		const obj = refractionData.finalRefraction?.day.default.eyes
		obj &&
			Object.keys(obj[eye]).forEach(key => {
				isFinal = isFinal || !isEmpty(obj[eye][key as keyof PhoropterSingleEye])
			})
	})

	if (isFinal) {
		return 'finalRefraction'
	}
	if (isCyclopedic) {
		return 'cycloplegicRefraction'
	}

	return 'subjectiveRefraction'
}
const noRoomSelectionStatus: ExamStatus[] = [
	'Planned',
	'PreTestFinished',
	'Waiting',
	'Ended',
	'DoctorEnded',
	'DoctorModeSelected',
	'Interrupted',
	'Uncompleted',
	'Canceled',
]

export const checkShowRoomSelection = (
	exam:
		| Pick<Exam, 'status' | 'externalAppointment' | 'store' | 'lockedBy'>
		| undefined,
	username: string,
	readonly: boolean | undefined,
) =>
	exam &&
	noRoomSelectionStatus.includes(exam!.status) === false &&
	!readonly &&
	isTodayZoned(exam.externalAppointment.date, exam.store.timezone) &&
	exam.lockedBy === username

export const isVideoMedia = (path: string) => {
	const videoExtensions = ['mp4']

	return videoExtensions.some(ext => {
		const regExp = new RegExp(`.${ext}$`, 'i')
		return !!path.match(regExp)
	})
}

export const hasEyeData = (eyeData: PhoropterSingleEye | undefined) => {
	const hasAdd = !!eyeData?.add
	const hasAxis = !!eyeData?.axis
	const hasCyl = !!eyeData?.cyl
	const hasHOrient = !!eyeData?.hOrient
	const hasHPrism = !!eyeData?.hPrism
	const hasSphere = !!eyeData?.sphere
	const hasVOrient = !!eyeData?.vOrient
	const hasVPrism = !!eyeData?.vPrism

	const result =
		hasAdd ||
		hasAxis ||
		hasCyl ||
		hasHOrient ||
		hasHPrism ||
		hasSphere ||
		hasVOrient ||
		hasVPrism

	return result
}

export const hasRefractionData = (
	refractionData: PhoropterDataDayOrNight | undefined,
) => {
	const hasODValues = hasEyeData(refractionData?.default.eyes.OD)
	const hasOSValues = hasEyeData(refractionData?.default.eyes.OS)

	const result = hasODValues || hasOSValues

	return result
}

export const getExamTestIdOrderPage = (test: ExamTest): string => {
	switch (test) {
		case 'A/C ratio':
			return 'acRatio'
		case 'Amplitude of accommodation':
			return 'amplitudeOfAccommodation'
		case 'Aniseikonia':
			return 'aniseikonia'
		case 'Anterior Segment':
			return 'anteriorSegment'
		case 'Autorefraction':
			return 'autorefraction'
		case 'Auxiliary testing':
			return 'autorefraction'
		case 'Blood pressure':
			return 'bloodPressure'
		case 'Color Vision':
			return 'colorTest'
		case 'Confrontational visual field':
			return 'visualFields'
		case 'Cycloplegic Refraction':
			return 'cycloplegicRefraction'
		case 'CT Near Alternating (Phoria)':
			return 'ctNearAlternating'
		case 'CT Near Unilateral (Tropia)':
			return 'ctNearUnilateral'
		case 'CT Distance Alternating (Phoria)':
			return 'ctDistanceAlternating'
		case 'CT Distance Unilateral (Tropia)':
			return 'ctDistanceUnilateral'
		case 'Diagnostic Pharmaceuticals':
			return 'diagnosticPharmaceuticals'
		case 'Doctor Interpretation Autorefraction':
			return 'doctorInterpretationAutorefraction'
		case 'Doctor Interpretation Visual Field':
			return 'doctorInterpretationVisualField'
		case 'Dry Retinoscopy':
			return 'dryRetinoscopy'
		case 'EOM':
			return 'extraocularMuscles'
		case 'Eye dominance':
			return 'eyeDominance'
		case 'Final Refraction':
			return 'finalRefraction'
		case 'Fundus':
			return 'fundus'
		case 'Fundus Exam Methods':
			return 'fundusExam'
		case 'Fusion':
			return 'fusion'
		case 'Goldmann Tonometry':
			return 'goldmanTonometry'
		case 'Gonioscopy':
			return 'gonioscopy'
		case 'Keratometry':
			return 'keratometry'
		case 'NPC':
			return 'NPC'
		case 'NRA/PRA':
			return 'nra/pra'
		case 'OCT':
			return 'oct'
		case 'Optic Nerve':
			return 'opticNerve'
		case 'Pachymetry':
			return 'pachymetry'
		case 'Phoria testing':
			return 'phoriaTesting'
		case 'Pupil testing':
			return 'pupil'
		case 'Pediatric Vitals':
			return 'pediatricVitals'
		case 'Pursuits & Saccades':
			return 'pursuitsAndSaccades'
		case 'Stereo':
			return 'stereoTest'
		case 'Stereopsis':
			return 'stereopsis'
		case 'Subjective Refraction':
			return 'subjectiveRefraction'
		case 'Tactile Tonometry':
			return 'tactileTonometry'
		case 'Tonometry':
			return 'tonometry'
		case 'Vergences':
			return 'vergeancesHorizontal'
		case 'Vertical Vergences':
			return 'vergeancesVertical'
		case 'Visual Acuity':
			return 'visualAcuity'
		case 'Vitals data':
			return 'vitalsData'
		case 'Wet Retinoscopy':
			return 'wetRetinoscopy'
		default:
			return ''
	}
}
