import { AssessmentPlanV2 } from '../components/AssessmentAndPlan/types'
import {
	DifForm,
	DifFormValues,
	TabDifForm,
} from '../components/DigitalIntakeForm/types'
import { RetinoscopyData } from '../components/Retinoscopy/models'
import { RecursivePartial } from '../utils/utilityTypes'

import { AppointmentType, TeloAppointment } from './appointment'
import { ChiefComplaint } from './chiefComplaint'
import { Code } from './coding'
import { Immunizations } from './immunizations'
import {
	InstrumentsData,
	PhoropterDataPart,
	PreTestData,
	RX_EYES,
} from './instruments'
import { HistoricalRXDataApi } from './instrumentsApi'
import { LensAddOn } from './lensAddOn'
import {
	ARDoctorInterpretationData,
	BinocularAssessmentData,
	BloodPressureData,
	ColorTestData,
	LensDesignData,
	LensMaterialData,
	ManualData,
	OcularHealthData,
	PachymetryManualData,
	PupilTestingData,
	StereoTestData,
	VisualAcuitiesData,
	VitalsData,
} from './manual'
import { CDAResponse, DateIsoString, Id } from './model'
import { MyopiaCareReport } from './myopia'
import { InternalPatient } from './patient'
import { Surgeon } from './practice'
import { LensDataSingleEye } from './prescription'
import { PrivacyPolicyDocumentDurationUnit, Store } from './store'
import { SurgeryCode } from './surgeryCondition'
import { CheckboxValue, CONTAINER_NAME, TypeCommons, YesNoValue } from './types'
import { VAScale } from './vaScales'
import { RefusalReason } from './refusalReason'
import { EmailAddress } from './ses'

export const APPOINTMENT_STATUS = [
	'arrived',
	'booked',
	'cancelled',
	'checked-in',
	'confirmed',
	'fulfilled',
	'noshow',
	'pending',
	'proposed',
	'waitlist',
	'active',
	'deleted',
	'billed',
	'expired',
	'missed',
	'entered-in-error',
] as const

export type AppointmentStatus = (typeof APPOINTMENT_STATUS)[number]

export type ExamStatus =
	| AppointmentStatus
	| 'Planned'
	| 'IntakeFormEnded'
	| 'PreTestStarted'
	| 'PreTestFinished'
	| 'RefractionistStarted'
	| 'RefractionistEnded'
	| 'DoctorModeSelected'
	| 'DoctorStarted'
	| 'DoctorEnded'
	| 'LensTrialQuit'
	| 'DoctorEndedWithLensTrial'
	| 'Ended'
	| 'Interrupted'
	| 'Uncompleted'
	| 'Canceled'
	| 'Paused'
	| 'Waiting'

export type MedicalInfo = {
	eyesDilatatedBefore: YesNoValue
	retinalImageBefore: YesNoValue
	pregnant: YesNoValue
	smoke: YesNoValue
	drink: YesNoValue
	medications: YesNoValue
	allergic: YesNoValue
	eyeTrauma: YesNoValue
	stopMedications: YesNoValue
	stopHistory: YesNoValue
	lazy: YesNoValue
	stopDiseases: YesNoValue
	doubleVision: YesNoValue
	surgery: YesNoValue
}

export type IntakeFormBase = MedicalInfo & {
	symptoms: string
}

export type IntakeForm = IntakeFormBase & {
	medicalIssues: string[]
	presentGlassAge: string
	lastExamDate: Date | null
}

export type IntakeFormUser = IntakeFormBase & {
	medicalIssues: CheckboxValue[]
	presentGlassAge: string
	lastExamDate: Date | null
}

export type DataExtraPatientToExam = {
	language: string
	gender: string
	occupation: string
}

export type IntakeFormApi = IntakeFormBase & {
	medicalIssues: string[]
	presentGlassAge: string
	lastExamDate: Date | undefined
}

export type DigitalIntakeForm = TabDifForm

export type ExamHistory = {
	prevStatus: ExamStatus
	currentStatus: ExamStatus
	statusUpdatedAt: Date
	statusUpdatedBy?: string //can be undefined in Planned status
}[]

export type LensAddOnsData = LensAddOn[]

export type UpdateLocalPretestDataType = {
	updatedData:
		| {
				historicalRX: HistoricalRXDataApi[]
		  }
		| { visualAcuities: VisualAcuitiesData }
		| { stereoTest: StereoTestData }
		| { colorTest: ColorTestData }
		| { bloodPressure: BloodPressureData }
		| { binocularAssessment: BinocularAssessmentData }
		| { vitalsData: VitalsData }
		| { arDoctorInterpretation: ARDoctorInterpretationData }
} & {
	exam: ExamApi
}

export type PretestDataApi = {
	arDoctorInterpretation: ARDoctorInterpretationData
	binocularAssessment: BinocularAssessmentData
	bloodPressure: BloodPressureData
	colorTest: ColorTestData
	historicalRX: HistoricalRXDataApi[]
	lensAddOns: LensAddOnsData
	lensDesign: LensDesignData
	lensMaterial: LensMaterialData
	ocularHealth: OcularHealthData
	pachymetry: PachymetryManualData
	pupil: PupilTestingData
	retinoscopy: RetinoscopyData
	stereoTest: StereoTestData
	visualAcuities: VisualAcuitiesData
	vitalsData: VitalsData
}

export type ExamMode = 'remote' | 'store' | 'assignToMe'

export type PrivacyPolicyDocumentSigned = {
	storeDocumentId: Id
	ref: string
	signedDate: string
	expirationDate: string
	duration?: number
	durationUnit?: PrivacyPolicyDocumentDurationUnit
	name?: string
}

export type DoctorNote = {
	text: string
	authorUsername: string
	timestamp: Date
}

export type DoctorNotesKeys =
	| keyof InstrumentsData
	| keyof Omit<ManualData, 'binocularAssessment'>
	| 'anteriorSegment'
	| 'chiefComplaint'
	| 'extraocular'
	| 'fundus'
	| 'gonioscopy'
	| 'opticalNerve'
	| 'phoria'
	| 'pupil'

export type DoctorNotes = {
	[k in DoctorNotesKeys]?: DoctorNote | undefined
}

export type NextEyeExam =
	| '1 month'
	| '2 months'
	| '3 months'
	| '4 months'
	| '5 months'
	| '6 months'
	| '9 months'
	| '12 months'
	| '13 months'
	| '2 weeks'
	| '1 week'
	| '3 days'
	| '2 days'
	| '1 day'
	| 'As Needed'

export type DoctorInterpretationEye = {
	full: boolean
	note?: DoctorNote
}

export type DoctorConfrontationalVFEye = {
	ftfc: boolean
	note?: DoctorNote
}

export type DoctorInterpretation = {
	OD: DoctorInterpretationEye
	OS: DoctorInterpretationEye
}

export type ExamConditionsData = {
	preTest?: string
	refraction?: string
	auxiliary?: string
	contactLenses?: string
}

export type DoctorConfrontationalVF = {
	OD: DoctorConfrontationalVFEye
	OS: DoctorConfrontationalVFEye
}

export type EyeHealthDiagnosi = {
	diagnosi: string
	note: string
}

export type EyeHealthDiagnosis = {
	lidsConjunctiva: EyeHealthDiagnosi[]
	cornea: EyeHealthDiagnosi[]
	retina: EyeHealthDiagnosi[]
}

export type EyeHealthCondition = {
	conditionName: string
	isSelf?: boolean
	isRelatives?: boolean
	relatives?: string[]
}

export type EyeHealthDocument = {
	timestamp: number
	fileName: string
}

export type EyeHealth = {
	medicalHistory?: EyeHealthCondition[]
	diagnosis: EyeHealthDiagnosis
	nextEyeExam?: NextEyeExam
	recommendations?: string
	document?: EyeHealthDocument
}

export type ReferralDocument = {
	timestamp: number
	fileName: string
	patientSigned?: boolean
	patientSignedAt?: number
	signed: boolean
	sent: boolean
}

export type ReferralFormData = {
	specialty: string
	referralDoctor: Id
	urgent: boolean
	referralReason: string
	referralReasonNote: string
	medicalValues?: PhoropterDataPart
	document?: ReferralDocument
}

export type HistoryDoctor = {
	doctor: string
	createdAt: Date
}

export type ExamChart = {
	container: string
	timestamp: number
	fileName: string
}

export type ExamMedicalReport = {
	id: Id
	container?: string
	timestamp?: number
	fileName?: string
	description: string
	attachment?: ExamDocument
	templateRef?: string
	templateBody?: string
	lastUpdateBy: string
	status: 'created' | 'cancelled'
}

export type AdditionalPrescription = ExamChart

export type ExamDocuments = {
	additionalPrescriptions?: AdditionalPrescription[]
	attachments?: ExamDocument[]
	charts?: ExamChart[]
	eyeHealth?: EyeHealth
	medicalReports?: ExamMedicalReport[]
	privacyPolicyDocuments?: PrivacyPolicyDocumentSigned[]
	referralForm?: ReferralFormData
	myopiaCare?: MyopiaCareReport
}

export type MedicationMetadata = {
	endDate?: string
}

export type Medication = MedicationMetadata & {
	finalDestinationType?: number
	finalStatusType?: number
	drugName?: string
	dosageNumberDescription?: string
	dosageForm?: string
	route?: string
	dosageFrequencyDescription?: string
	takeAsNeeded?: string
	dispenseAsWritten?: string
	dosageNumberTypeID?: number
	dosageFormTypeId?: number
	dosageRouteTypeId?: number
	daysSupply?: number
	prescriptionNotes?: string
	pharmacistNotes?: string
	externalPhysicianID?: string
	pharmacyType?: number
	pharmacyDetailType?: number
	externalUserID?: string
	externalUserType?: string
	locationName?: string
	dateMovedToPreviousMedications?: {
		'@_xml:space': string
	}

	dispense?: number
	drugInfo: string
	genericName: string
	patientFriendlySIG: string
	physicianName: string
	prescriptionDate: string // i.e.: 2022-05-10T04:19:42.567-05:00
	prescriptionGuid: string
	refills: number
	rXcui?: number
	status: string
}

export type MedicationHistory = {
	currentMedicationDocumented?: {
		value?: string
		reason?: Partial<RefusalReason>
	}
	medicationNotOrdered?: {
		pharmacologicTherapy?: boolean
		medicationsForAbove?: boolean
		medicationsForBelow?: boolean
		tobacco?: boolean
		reason?: Partial<RefusalReason>
	}
	medications?: Medication[]
}

export type OrderStatusType =
	| 'draft'
	| 'created'
	| 'resultsReceived'
	| 'closed'
	| 'cancelled'
	| 'moved'
	| 'refused'
	| 'ordered'

export type Order = {
	id: string
	orderType: OrderType
	orderContextId?: string
	orderContextName?: string
	orderSubtype?: OrderSubtype
	alreadyRequestedOrderDate?: Date
	activity?: 'today' | 'schedule' | 'referral'
	reasonIcd?: string
	reasonCpt: string
	snomedctTerm?: string
	caseSignificanceId?: string
	reasonSnomedCt?: string
	reasonLoinc?: string
	reasonRefusal?: RefusalReason
	reasonRefusalNote?: string
	status: OrderStatusType
	schedule?: Schedule
	laboratoryTest?: LaboratoryTest
	specialTest?: SpecialTest
	referral?: {
		type?: string
		from?: string
		toAddress?: Array<{ value: string; label: string } | string>
		useStoreReferralDoctor: boolean
		specialty?: string
		referralDoctor?: string
		referralDoctorName?: string
		referralDoctorManual?: {
			fullName?: string
			emailAddress?: string
			phone?: string
			practice?: string
			address?: string
			city?: string
			state?: string
			zip?: string
		}
		referralDoctorEmail?: string
		referralReason?: string
		description?: string
		subject?: string
		message?: string
		selectedExams?: any
		isSesOrder?: boolean
		sesMessageId?: string
		cda?: CDAResponse
	}
	results: OrderResult[]
	document?: ReferralDocument
	note?: string
	createdAt?: Date
	cancelledReasonWhy?: string
	createdBy: string
	examStatus?: ExamStatus
	examId?: string
	duplicatedFrom?: string
	duplicatedFromDate?: Date
	device?: string
}

export type OrderType =
	| 'referral'
	| 'laboratory'
	| 'specialTest'
	| 'alreadyRequested'
	| 'intervention'
	| 'device'

export type OrderSubtype = Omit<OrderType, 'alreadyRequested' | 'device'>

export type OrderResult = {
	id: string
	attachments?: ExamOrderResultDocument[]
	note?: string
	tests?: string[]
	resultCode?: {
		value?: string
		description?: string
	}
	stdCode?: string
	result?: {
		value: string
		units?: string
		range?: string
		abnormalFlag?: string
	}
	date?: Date
	laboratory?: string
	specimen?: {
		source?: string
		disposition?: string
	}
	diagnosticImagingReport?: boolean
	diagnosticImagingReportNote?: string
	images?: ExamDocument[]
	createdBy: string
	createdAt: Date
}

export type OrderResultUnit = {
	abbreviation: string
	label: string
}

export type LaboratoryTest = {
	cpt?: {
		code: string
		description: string
	}
	loinc?: {
		code: string
		description: string
	}
}

export type SpecialTest = {
	cpt?: {
		code: string
		description?: string
	}
}

export type Schedule = {
	scheduleIn?: number
	unit?: ScheduleUnit
	approxDate?: Date
}

export type ScheduleUnit =
	| 'days'
	| 'weeks'
	| 'months'
	| 'years'
	| 'specificDate'

export const PAGE_KEYS: Readonly<PageKey[]> = [
	'assessmentPlan',
	'auxiliary',
	'consultation',
	'contactLenses',
	'dif',
	'ocularHealth',
	'orders',
	'otherTest',
	'pretest',
	'refraction',
	'summary',
	'surgery',
	'visionTherapy',
	'EHRExport',
	'attachment',
] as const

export enum ExamPage {
	Dif = 'dif',
	Pretest = 'pretest',
	Refraction = 'refraction',
	Auxiliary = 'auxiliary',
	ContactLenses = 'contactLenses',
	OcularHealth = 'ocularHealth',
	Surgery = 'surgery',
	OtherTest = 'otherTest',
	AssessmentPlan = 'assessmentPlan',
	Orders = 'orders',
	VisionTherapy = 'visionTherapy',
	Consultation = 'consultation',
	EHRExport = 'EHRExport',
	Attachment = 'attachment',
}

export enum ExamStep {
	Summary = 0,
	Anamnesis = 1,
	PreTest = 2,
	Refraction = 3,
	Auxiliary = 4,
	ContactLenses = 5,
	OcularHealth = 6,
	Surgery = 7,
	OtherTest = 8,
	VisionTherapy = 9,
	AssessmentPlan = 10,
	Consultation = 11,
	Orders = 12,
	EHRExport = 13,
	Attachment = 14,
	Documents = 15,
	ExamUpdates = 16,
}

export enum AnamnesisSteps {
	ChiefComplaint = 0,
	IntakeForm = 1,
	MedicationHistory = 2,
}

export enum RefractionSteps {
	ClassicExam = 0,
	MyopiaExam = 1,
}

export enum ConsultationSteps {
	ClinicalData = 0,
	Prescriptions = 1,
	AdditionalForms = 2,
	DiseaseAndTreatment = 3,
	VisualSimulation = 4,
}

export enum AssessmentAndPlanSteps {
	AssessmentPlan = 0,
	Procedure = 1,
	ImplantableDevices = 2,
	ScreeningProcedures = 3,
	Goals = 4,
	CodesToBill = 5,
}

export enum OrdersSteps {
	Orders = 0,
}

export const enum ExamUpdatesSteps {
	EmergencyAccess = 0,
	ExamUpdates = 1,
}

export type PageKey =
	| 'assessmentPlan'
	| 'auxiliary'
	| 'consultation'
	| 'contactLenses'
	| 'dif'
	| 'ocularHealth'
	| 'orders'
	| 'otherTest'
	| 'pretest'
	| 'refraction'
	| 'summary'
	| 'surgery'
	| 'visionTherapy'
	| 'EHRExport'
	| 'attachment'

export type PanelKey =
	| PageKey
	| 'additionalForms'
	| 'autorefraction'
	| 'binocularAssessment'
	| 'bloodPressure'
	| 'cda'
	| 'chiefCompliant'
	| 'clinicalData'
	| 'codesToBill'
	| 'colorTest'
	| 'cycloplegicRefraction'
	| 'diagnosis'
	| 'diseasesAndTreatments'
	| 'finalRefraction'
	| 'goals'
	| 'healthHistory'
	| 'historicalRx'
	| 'images'
	| 'implantableDevices'
	| 'imunizations'
	| 'insuranceInfo'
	| 'iop'
	| 'lensmeter'
	| 'mainPage'
	| 'myopia'
	| 'myopiaCare'
	| 'oct'
	| 'patient'
	| 'orders'
	| 'personalInfo'
	| 'prescription'
	| 'procedure'
	| 'pupil'
	| 'pursuitsAndSaccades'
	| 'retinalImaging'
	| 'retinoscopy'
	| 'RXPrescribed'
	| 'screeningProcedures'
	| 'stereoTest'
	| 'subjectiveRefraction'
	| 'summaryAll'
	| 'tab'
	| 'tonometer'
	| 'trialCL'
	| 'unaidedVisualAcuities'
	| 'vergeancesHorizontal'
	| 'vergeancesVertical'
	| 'videoSlitLamp'
	| 'visualAcuities'
	| 'visualFields'
	| 'vitalsData'
	| 'chiefComplaint'
	| 'medicationHistory'
	| 'familyAndPatientHistory'
	| 'lifestyle'
	| 'visionStatus'
	| 'EHRExport'
	| 'attachment'
	| 'documents'
	| 'examUpdates'
	| 'myopiaExam'
	| 'prescriptions'
	| 'anamnesis'
	| 'intakeForm'
	| 'consents'

export type ExamPagesMetadata = {
	modifiedBy: string
	modifiedByUser: string
	isDoctorTemplate: boolean
}

export type ExamPagePanels = {
	[key in PanelKey]?: boolean
}

export type ExamPages = {
	[key in PageKey]: ExamPagePanels
}

export type ExamTemplateMetaData = {
	modifiedBy: string
	modifiedByUser: string
	createdBy?: string
	createdByUser?: string
	isDefault: boolean
	dormantForUsers?: string[]
	isDoctorTemplate?: boolean //used for flows customized during exam only
} & (
	| { legalEntityId: string; appointmentTypeCode: string; templateName: null }
	| {
			legalEntityId: null
			appointmentTypeCode: null
			templateName: string
	  }
)

export type ExamTemplateUserMetadata = {
	deletable: boolean
	editable: boolean
}

export type ExamTemplate = TypeCommons & {
	pages: ExamPages
	metadata: ExamTemplateMetaData
}

export type ExamTemplateDiff = RecursivePartial<ExamTemplate>
export type ExamTemplateWithId = ExamTemplate & { _id: string }

export type FhirIdentifiers = {
	fhirAppointmentId?: string
	fhirEncounterId?: string
	fhirPatientId?: string
	fhirPatientUpriseId?: string
}

export type LockedByHistoryItem = {
	username: string
	timestamp: DateIsoString
	role?: string
}

export type ExamLink = {
	id: string
	url: string
	timestamp: number
	linkTextOptional: string
	description: string
	uploadedBy: string
}

export type RecordToDelete = {
	shouldBeDeleted?: boolean
	requestedUserName?: string
}

export type ExamBase = ExamDocuments & {
	_id: Id
	appointmentId: Id
	assessmentPlan?: AssessmentPlanV2
	auxiliary?: AuxiliaryData
	canRefreshDocuments?: boolean
	chartAvailable?: boolean
	checkedInAt?: DateIsoString
	chiefComplaintCategories?: ChiefComplaint[]
	cockpitTechnician?: string
	coding?: Code[]
	conditions?: ExamConditionsData
	confrontationalVF?: DoctorConfrontationalVF
	createdAt: DateIsoString
	dif: DifForm
	doctor: string
	doctorInterpretation?: DoctorInterpretation
	doctorName?: string
	doctorNotes: DoctorNotes
	doctorSurname?: string
	endDateTime?: Date
	examType?: AppointmentType // TODO: migrate old exams to insert the examType field and then make it mandatory
	externalAppointment: TeloAppointment
	externalDif?: DigitalIntakeForm // TabDifForm
	fhirIdentifiers?: FhirIdentifiers
	gender: string
	goals?: Goal[]
	healthConcerns?: string[]
	history: ExamHistory
	historyDoctor?: HistoryDoctor[]
	implantableDevices?: ImplantableDevice[]
	intakeForm?: IntakeForm
	internalPatient: InternalPatient
	interruptedBy?: string
	interruptedByRole?: string
	interruptionReason?: string
	isTest?: boolean
	language: string
	localTechnician: string
	localTechnicianName?: string
	localTechnicianSurname?: string
	lockedBy?: string
	lockedByHistory?: LockedByHistoryItem[]
	medicalReportNote?: string
	medicationHistory?: MedicationHistory
	immunizations?: Immunizations[]
	mode?: ExamMode
	noRxNotes?: string
	occupation: string
	orders?: Order[]
	pages: ExamPages
	pagesMetadata?: ExamPagesMetadata
	patientId: string
	previousExamId?: string
	procedures?: Procedure[]
	readonly?: boolean
	refractionist: string
	screeningProcedures?: ScreeningProcedure[]
	screeningProcedureMappings?: ScreeningProcedureMapping[]
	recordToDelete?: RecordToDelete
	startDateTime?: Date
	store: Store
	updatedAt: Date
	updatedBy: string
	vaScaleUsed: VAScale
	surgeries?: ExamSurgery[]
	codingUpriseId?: number
	links?: ExamLink[]
	tabAppointmentId?: string
	queue?: {
		history: {
			status: 'IN' | 'OUT'
			timestamp: string
			username: string
		}[]
	}
}

export type Exam = ExamBase & {
	status: ExamStatus
	preTest: PreTestData
	discardPhoropterReadingsBefore: number
	phoropterIsResetting: boolean
	previousExamId?: string
	contactLensesTrial?: ContactLenses[]
	contactLensesHistory?: ContactLensesHistory[]
	otherTest?: OtherTest[]
	visionTherapy?: VisionTherapy | VisionTherapyApi
	isDifOpen?: boolean
	privacyPolicyDocuments?: PrivacyPolicyDocumentSigned[]
}

export type ExamApi = ExamBase & {
	status: ExamStatus
	preTest: PretestDataApi
	contactLensesTrial?: ContactLensesApi[]
	contactLensesHistory?: ContactLensesHistory[]
	otherTest?: OtherTest[]
	visionTherapy?: VisionTherapy
}

export interface StrippedExamHasDocuments
	extends Record<keyof ExamWorklistDocuments, boolean> {
	prescription: boolean
	surgeries: boolean
}

export type StrippedExam = {
	_id: string
	appointmentId: string
	contactLensesHistory?: ContactLensesHistory[]
	createdAt: DateIsoString
	checkedInAt?: DateIsoString
	doctor?: string
	doctorName?: string
	doctorNotes: DoctorNotes
	doctorSurname?: string
	endDateTime?: Date
	examType: AppointmentType
	hasCharts?: boolean
	history: ExamHistory
	historyDoctor?: HistoryDoctor[]
	internalPatient: InternalPatient
	interruptionReason?: string
	isStripped: boolean
	language: string
	localTechnician: string
	localTechnicianName?: string
	localTechnicianSurname?: string
	mode?: ExamMode
	orders?: Order[]
	patientId: string
	privacyPolicyDocuments: PrivacyPolicyDocumentSigned[]
	startDateTime?: Date
	status: ExamStatus
	store: Store
	tabAppointmentId?: string
	externalAppointment: TeloAppointment
	updatedAt: Date
	contactLensesTrial?: ContactLenses[]
	hasDocuments: Partial<StrippedExamHasDocuments>
}

export type NewExamData = {
	appointmentId: Id
	externalDif?: DigitalIntakeForm
	doctorNotes: DoctorNotes
	examType: string
	intakeForm?: IntakeFormApi
	internalPatient: Id
	isTest?: boolean
	localTechnician?: string
	localTechnicianName?: string
	localTechnicianSurname?: string
	mode: string
	patientId: Id
	privacyPolicyDocuments: PrivacyPolicyDocumentSigned[]
	status?: ExamStatus
	store: Id
	storeAssistant?: string
	externalAppointment: TeloAppointment
	vaScaleUsed: VAScale
	visionTherapy: VisionTherapy
}

export type ExamQuery = {
	statusFrom?: ExamStatus
	statusTo?: ExamStatus
	dateFrom?: string
	dateTo?: string
	states?: string
	stores?: string
	languages?: string
	patientId?: string
	internalPatientId?: Id
	appointmentId?: Id
	storeId?: Id
	mode?: ExamMode
	doctor?: string
	username?: string
}

export type WorklistExamsQuery = {
	chartStatus?: string
	cpt?: string
	dateFrom?: string
	dateTo?: string
	icd10?: string
	loinc?: string
	onlyRemote?: boolean
	page: number
	snomed?: string
	status?: string
	storeId?: Id
	technician?: string
	patientSignatureStatus?: string
}

export type WorklistExamsQueryWithAvailability = WorklistExamsQuery & {
	isAvailable?: boolean
}

export type WorklistExamCodes = {
	cpt: { code: string; description: string }[]
	snomed: { code: string; description: string; caseSignificanceId: string }[]
	icd10: { code: string; description: string }[]
	loinc: { code: string; description: string }[]
}

export type ExamExtra = {
	occupation: string
	gender: string
	language: string
}

export type CLAidedVAValue = {
	distance: string
	near: string
	distanceAdditional?: number
	nearAdditional?: number
}

export type CLOverRefractionValue = {
	sphere: string | null
	cylinder: string | null
	axis: string
}

export type CLEvaluationValue = {
	movement: string
	alignment: string
	centration: string
	rotation: string
	rotationDirection: string
	comfort?: string
	coverage?: 'FULL' | 'INCOMPLETE'
}

type CLCustomMeasureValue = {
	baseCurve2?: string
	diameter2?: string
	sphere2?: string
	asphericity?: string
	secondaryCurveRadius?: string
	secondaryCurveRadius2?: string
	secondaryCurveWidth?: string
	secondaryCurveWidth2?: string
	peripheralCurveRadius?: string
	peripheralCurveWidth?: string
	skirtCurve?: string
	centerThickness?: string
	edgeThickness?: string
	edgeBevel?: string
	segmentHeight?: string
	segmentWidth?: string
	blend?: string
	dot?: boolean
	color?: string
	opticalZoneDiameter?: string
	prism?: string
	addition?: string
	plasmaCoating?: string
	sagittal?: string
}

export type CLMeasureValue = {
	baseCurve?: string
	diameter?: string
	sphere?: string
	cylinder?: string
	axis?: string
	addition?: string
	description?:
		| 'HI'
		| 'HIGH'
		| 'MED'
		| 'MEDIUM'
		| 'MID'
		| 'LO'
		| 'LOW'
		| 'D'
		| 'N'
} & CLCustomMeasureValue

export type CLEvaluation = {
	OS: CLEvaluationValue
	OD: CLEvaluationValue
}

export type CLAidedVA = {
	OD: CLAidedVAValue
	OS: CLAidedVAValue
	OU: CLAidedVAValue
}

export type CLMeasure = {
	OD: CLMeasureValue
	OS: CLMeasureValue
}

export type ContactLensEyeType = keyof CLMeasure

export type CLOverRefraction = {
	OD: CLOverRefractionValue
	OS: CLOverRefractionValue
}

export type VisualAcuityBothMeasure = {
	distance: string
	distanceAdditional: string
	near: string
	nearAdditional: string
}

export type VisualAcuitySingleEyeMeasure = VisualAcuityBothMeasure & {
	distancePH: string
	nearPH: string
}

export type VisualAcuityMeasure = {
	OD: VisualAcuitySingleEyeMeasure
	OS: VisualAcuitySingleEyeMeasure
	BOTH: VisualAcuityBothMeasure
}

export type CLStatus = 'PRESCRIBED' | 'REFUSED' | 'TRIAL' | 'NONE'
export type CLTrialType = {
	value: 'IN-OFFICE' | 'DISPENSED-TODAY' | 'ORDER-FOR-DISPENSE' | 'NOT-TRIED'
	label: string
}
export const CLTrialTypeArray: CLTrialType[] = [
	{
		value: 'IN-OFFICE',
		label: 'lensesTrial.trialTypes.IN-OFFICE',
	},
	{
		value: 'DISPENSED-TODAY',
		label: 'lensesTrial.trialTypes.DISPENSED-TODAY',
	},
	{
		value: 'ORDER-FOR-DISPENSE',
		label: 'lensesTrial.trialTypes.ORDER-FOR-DISPENSE',
	},
	{
		value: 'NOT-TRIED',
		label: 'lensesTrial.trialTypes.NOT-TRIED',
	},
]

export type CLModality = {
	value: 'Daily wear' | 'Extended wear' | 'Continuous wear'
	label: string
}
export const CLModalityArray: CLModality[] = [
	{
		value: 'Daily wear',
		label: 'lensesTrial.trialModalities.dailyWear',
	},
	{
		value: 'Extended wear',
		label: 'lensesTrial.trialModalities.extendedWear',
	},
	{
		value: 'Continuous wear',
		label: 'lensesTrial.trialModalities.continuousWear',
	},
]

export type CatalogueId = { [key in keyof CLMeasure]: string }

export type CLSpecialtyLensType = 'RGP' | 'Scleral' | 'OrthoK' | 'Hybrid'
export type CLSpecialtyLens = {
	OD?: CLSpecialtyLensType
	OS?: CLSpecialtyLensType
}

export type CLAdditionalParameters = {
	OD?: string
	OS?: string
}

export interface ContactLensesField<T> {
	OD?: T
	OS?: T
}

export type ContactLenses = {
	suggestedCatalogueId: CatalogueId
	catalogueId: CatalogueId
	manufacturer: ContactLensesField<string>

	designName?: ContactLensesField<string>
	material?: ContactLensesField<string>

	lensType: ContactLensesField<string>
	aidedVA: CLAidedVA
	evaluation: CLEvaluation
	followUpDate?: Date
	measure: CLMeasure
	modality?: CLModality['value']
	newSlExamInProgress: boolean
	notes?: string
	overRefraction: CLOverRefraction
	selectedEye: RX_EYES
	status: CLStatus
	trialType?: CLTrialType['value']
	specialtyLens?: CLSpecialtyLens
	additionalParameters?: CLAdditionalParameters
	timestamp?: number
	doctorUsername?: string
	custom?: boolean
	condition?: string
	monovision?: ContactLensesField<string>
	wearSchedule?: ContactLensesField<string>
	disinfectingSolution?: ContactLensesField<string>
	replenishment?: ContactLensesField<string>
	quantity?: ContactLensesField<number>
}

export type ContactLensesHistory = {
	catalogueId: CatalogueId
	trialType?: CLTrialType
	modality?: CLModality
	measure: CLMeasure
	useSameProduct: boolean
}

export type OtherTest = {
	code: string
	name: string
	images?: ExamDocument[]
	notes?: string
	attachment?: ExamDocumentWithFileSize
}

export type ContactLensesApi = Omit<ContactLenses, 'catalogueId'> & {
	catalogueId: {
		L: Id
		R: Id
	}
}

export type ContactLensesBase = {
	name: string
	brand: string
	lensType: string
	baseCurve: string[]
	diameter: string[]
	sphere: string[]
	cyl: string[]
	add: string[]
	additionalParams: string
	enabled: boolean
	custom: boolean
}
export type ContactLensesCatalogData = ContactLensesBase & {
	_id: Id
}

export type DiagnosisCatalogData = {
	_id: Id
	Code: string
	Description: string
	CarePlan: string
	Status: string
	Section?: string
	Diagnosi?: string
}

export type HealthDescriptionsCatalogData = {
	diagnosi: string
	section: string
	description: string
}

export interface ExamDocument {
	ref: string
	documentName: string
	fileName: string
	container: CONTAINER_NAME
	timestamp: number | string
	documentType?: string
	description?: string
	uploadedBy?: string
}

export type ExamDocumentToSave = ExamDocument & {
	toSave?: boolean
}

export type ExamOrderResultDocument = ExamDocumentToSave & {
	uploaded?: boolean
}

export interface ExamDocumentWithFileSize extends ExamDocumentToSave {
	fileSize?: number
}

export type AuxiliaryData = {
	vergeancesHorizontal?: VergeancesData
	vergeancesVertical?: VergeancesVerticalData
	pursuitsAndSaccades?: PursuitSaccadesData
}
export type VergeancesData = {
	bi: {
		dist: VergeancesRowData
		near: VergeancesRowData
	}
	bo: {
		dist: VergeancesRowData
		near: VergeancesRowData
	}
}

export type VergeancesVerticalData = {
	buOS: VergeancesRowData
	buOD: VergeancesRowData
	bdOS: VergeancesRowData
	bdOD: VergeancesRowData
}

export type VergeancesRowData = {
	blur?: string
	break: string
	recovery: string
}

export type PursuitSaccadesData = {
	OD: PursuitSaccadesEyeData
	OS: PursuitSaccadesEyeData
}

export type PursuitSaccadesEyeData = {
	safe: boolean
	abnormal: boolean
	notes?: string
}

export type Goal = {
	title: string
	notes?: string
	code?: string
	cid?: string
}

export type ExportToolData = {
	assessmentAndPlanSections?: string[]
	ocularHealthSections?: string[]
	auxiliarySections?: string[]
	medications?: boolean
}

export type Procedure = {
	description?: string
	modifiers?: Modifier[]
	status?: string
	statusOdOs?: string
	plan?: string
	planOdOs?: string
	eye?: string
	notes?: string
	title?: string
	code: string
	images?: ProcedureImage[]
	reasonSnomedCt?: string
}

export type Modifier = {
	_id: string
	code: string
	description: string
}

export type ProcedureImage = ExamDocument & {
	empty?: boolean
	type: string
}

export type ImplantableDevice = {
	isActive: boolean
	udi: {
		udi: string
		di: string
		lotNumber?: string
		serialNumber?: string
		expirationDate?: string
		expirationDateOriginal: string
		manufacturingDate?: string
		manufacturingDateOriginal?: string
		expirationDateOriginalFormat?: string
		manufacturingDateOriginalFormat?: string
	}
	gudid: {
		device: {
			brandName: string
			versionModelNumber: string
			companyName: string
			MRISafetyStatus: string
			labeledNoNRL: boolean
			deviceHCTP: boolean
			gmdnTerms: {
				gmdn: {
					gmdnPTName: string
				}[]
			}
		}
	}
}

export type ImplantableDeviceError = {
	error: string
}

export type ScreeningProcedure = {
	id: string
	name?: string
	isBack?: boolean
	notes?: string
	images?: ExamDocument[]
	attachment?: ExamDocumentWithFileSize
}

export type ScreeningProcedureMapping = {
	procedure: string
	cptCodes: string[]
	modifiedBy: string
}

export type MyopiaRefraction = {
	R: LensDataSingleEye
	L: LensDataSingleEye
	sensitivity?: number
}

export type WithExamId<T> = T & {
	examId: string
}

export type OrderHistory = Order & {
	examId: string
}

export type Diary = {
	examDate: Date
	modifiedBy?: string
	todayActivity?: string
	nextSessionActivity?: string
}

export type Diaries = {
	current: Diary
	previous: Diary[]
}

export type VisionTherapy = {
	recommendation?: string
	diary: Diary
}

export type VisionTherapyApi = {
	recommendation?: string
	diaries: Diaries
}

export type ExamWorklistDocuments = ExamDocuments & {
	contactLensesTrial?: ContactLenses[]
	orders?: Order[]
	eyeHealthDocument?: EyeHealth['document']
	images?: ExamDocument[]
}

export type WorklistExam = Pick<
	Exam,
	| '_id'
	| 'checkedInAt'
	| 'doctor'
	| 'examType'
	| 'externalAppointment'
	| 'history'
	| 'internalPatient'
	| 'localTechnician'
	| 'mode'
	| 'queue'
	| 'status'
> & {
	codes: WorklistExamCodes
	createdAt: string
	doctorFullName: string
	documents: ExamWorklistDocuments
	ehr: boolean
	examDif?: DifFormValues
	hasCharts: boolean
	imported: boolean
	location: string
	patientSignatureStatus: string
	remote: boolean
	rxId?: string
	rxSent?: boolean
	storeId: string
	technicianFullName: string
	timezone: string
	updatedAt: string
}

export type ExamSurgery = {
	id: Id
	procedureDate: Date
	defaultCondition?: Id
	eyeSurgery: 'yes' | 'no' | ''
	eyeSurgerySel?: 'OD' | 'BIN' | 'OS'
	status: 'created' | 'cancelled'
	surgeons: Surgeon[]
	procedureCTPs: SurgeryCode[]
	preDiagnosisICDs: SurgeryCode[]
	postDiagnosisICDs: SurgeryCode[]
	anesthesia: SurgeryCode[]
	meds: string
	note: string
	complications?: string
	reasonSnomedCt?: string
	createdBy: {
		username: string
		fullName: string
	}
	timestamp: number
	document?: {
		timestamp: number
		fileName: string
		container: string
	}
}

export type ExamQueue = {
	position: number
}

export type SaveExamConditionPayload = {
	condition: string
	section: 'PreTest' | 'Refraction' | 'Auxiliary' | 'CL'
}
