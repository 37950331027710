import React from 'react'
import styled from 'styled-components'

import {
	Checkbox as MCheckbox,
	FormControlLabel,
	FormControlLabelProps,
	Typography,
} from '@mui/material'

import ThickIcon from './icons/ThickIcon'
import theme from './theme'
import MinusIcon from './icons/MinusIcon'
import { pxToRem } from '../libs/style'

const StyledFormControlLabel = styled(FormControlLabel)<{
	$marginRight?: string
	$marginLeft?: string
	$paddingRight?: number
	$paddingLeft?: number
}>`
	${props =>
		props.$marginRight &&
		`
		margin-right: ${props.$marginRight};
	`}
	${props =>
		props.$marginLeft &&
		`
		margin-left: ${props.$marginLeft};
	`}
	${props =>
		props.$paddingRight &&
		`
		padding-right: ${theme.spacing(props.$paddingRight)};
	`}
	${props =>
		props.$paddingLeft &&
		`
		padding-left: ${theme.spacing(props.$paddingLeft)};
	`}


	.MuiTypography-root {
		font-size: ${pxToRem(14)}rem;
	}
`

const OutlinedCheckbox = styled(MCheckbox)<{
	$marginRight?: string
	$marginLeft?: string
	$margin?: string
}>`
	background-color: ${theme.palette.common.white};
	border: 2px solid ${theme.palette.grey[600]};
	border-radius: 5px;
	height: 20px;
	width: 20px;
	padding: 0;
	margin-right: ${props =>
		props.$marginRight ? props.$marginRight : `${theme.spacing(1)}`};
	${props => (props.$marginLeft ? `margin-left: ${props.$marginLeft}` : '')};
	${props => (props.$margin ? `margin: ${props.$margin}` : '')};

	&:hover {
		border-color: ${theme.palette.violet[800]};
	}

	&.Mui-disabled {
		opacity: 0.5;
		border-color: ${theme.palette.greyCustom.main};
	}

	&.Mui-focusVisible {
		border-color: ${theme.palette.grey[600]};
		box-shadow: 0 0 0 1px ${theme.palette.common.white},
			0 0 0 3px ${theme.palette.secondary.light};
		background-color: ${theme.palette.common.white};
	}

	&.Mui-checked {
		background-color: ${theme.palette.primary.main};
		border-color: ${theme.palette.primary.main};
		color: ${theme.palette.background.default};

		&:hover {
			background-color: ${theme.palette.violet.dark};
			border-color: ${theme.palette.violet.dark};
		}

		&.Mui-disabled {
			opacity: 0.5;
			border-color: ${theme.palette.greyCustom.main};
			background-color: ${theme.palette.greyCustom.main};
		}

		&.Mui-focusVisible {
			background-color: ${theme.palette.violet.dark};
			border-color: ${theme.palette.violet.dark};
			box-shadow: 0 0 0 1px ${theme.palette.common.white},
				0 0 0 3px ${theme.palette.secondary.light};
		}
	}

	.MuiSvgIcon-root {
		display: none;
	}
`

interface Props extends Omit<FormControlLabelProps, 'control'> {
	$defaultStyle?: boolean
	marginRight?: string
	marginLeft?: string
	paddingRight?: number
	paddingLeft?: number
	customCheckedIcon?: React.ReactNode
	indeterminate?: boolean
	fontWeight?: number
}

const Checkbox: React.FC<Props> = ({
	checked,
	value,
	onChange,
	label,
	$defaultStyle = false,
	marginRight,
	marginLeft,
	paddingRight,
	paddingLeft,
	customCheckedIcon = <ThickIcon />,
	indeterminate = false,
	fontWeight,
	...props
}) => {
	return (
		<StyledFormControlLabel
			className={'telo-checkbox'}
			control={
				<OutlinedCheckbox
					checked={checked}
					onChange={onChange}
					checkedIcon={customCheckedIcon}
					$marginRight={marginRight}
					$marginLeft={marginLeft}
					indeterminate={indeterminate}
					indeterminateIcon={<MinusIcon />}
				/>
			}
			label={
				<Typography
					color="primary"
					fontWeight={fontWeight ? fontWeight : 'medium'}
				>
					{label}
				</Typography>
			}
			$marginRight={marginRight}
			$marginLeft={marginLeft}
			$paddingRight={paddingRight}
			$paddingLeft={paddingLeft}
			{...props}
		/>
	)
}

export default Checkbox
